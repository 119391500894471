<template>
  <div class="container-fluid">
    <ToolBar :showBread="false" :showSelect="false" :ShowAddBtn="false"/>
    <div class="row">
      <div class="col-12 col-md-4 mb-1 ms-auto  d-flex justify-content-end">

        <el-input v-model="filter.search" class="align-self-end" clearable
                  :placeholder="getMessageByCode('search') ?? 'Search here...'"
                  @clear="getQuotes"
                  @keyup="getQuotes">
          <template #append>
            <el-button @click="getQuotes">
              <template #icon>
                <i class="bi bi-search"></i>
              </template>
            </el-button>
          </template>
        </el-input>
        <el-button type="primary" class="ms-2" @click="resetIQuote();addDialog = true">
          {{ getMessageByCode('addQuote') ?? 'Add Quote' }}
        </el-button>
      </div>
    </div>
    <div class="mt-2">
      <el-table :data="quotes" header-cell-class-name="tbl-header">
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              size="small"
          >
          </el-pagination>
        </template>
        <el-table-column :show-overflow-tooltip="true" :label="getMessageByCode('author') ?? 'Author'" prop="author"
                         width="180"/>
        <el-table-column :show-overflow-tooltip="true" :label="getMessageByCode('quote') ?? 'Quote'"
                         prop="quote" min-width="200"/>
        <el-table-column :label="getMessageByCode('active')??'Active'" prop="isActive" width="120">
          <template #default="scope">
            <el-tag v-if="scope.row.isActive" type="success" size="small">Yes</el-tag>
            <el-tag v-else type="danger" size="small">Yes</el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="getMessageByCode('action') ?? 'Action'" prop="sortOrder" width="80">
          <template #default="scope">
            <el-space>
              <i class="mgc_pen_line action-btn text-primary" @click="editQuote(scope.row,'edit')"></i>
              <i class="mgc_delete_2_line action-btn text-danger" @click="editQuote(scope.row,'del')"></i>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--    Dialogs-->
    <el-dialog v-model="addDialog" :align-center="true"
               :title="quote.id < 1 ? getMessageByCode('addQuote') ?? 'Add Quote' : getMessageByCode('updateQuote') ?? 'Update Quote'"
               style="width: auto; max-width: 500px">
      <el-form class="row" require-asterisk-position="right" label-position="top">
        <el-form-item :label="getMessageByCode('author') ?? 'Author'">
          <el-input v-model="quote.author"></el-input>
        </el-form-item>
        <el-form-item :label="getMessageByCode('quote') ?? 'Quote'">
          <el-input type="textarea" :rows="3" v-model="quote.quote"></el-input>
        </el-form-item>
        <el-form-item :label="getMessageByCode('isActive') ?? 'Is Active'">
          <el-switch v-model="quote.isActive"></el-switch>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button v-if="quote.id < 1" type="primary" class="ms-2" @click="addDialog = false; addQuote()">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addDialog = false; updateQuote()">
            {{ getMessageByCode('update') ?? 'Update' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" :align-center="true"
               :title="getMessageByCode('delete') ?? 'Delete Quote'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold text-danger"> {{
               quote.author + ' ' + getMessageByCode('quote') ?? 'quote?'
             }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="danger" class="ms-2" @click="deleteDialog = false; deleteQuote(quote.id)">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <n-spin v-if="loading" size="small" stroke="#000" :stroke-width="15" description="Loading"
            class="cstm-spin"></n-spin>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getMessageByCode} from "@/Utility/getMessagebyCode";
import {NSpin} from "naive-ui";
import loginCoreService from "@/Services/ComponentsServices/loginCoreService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "LoginQuotes",

  components: {NSpin, ToolBar},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      fileInputKey: new Date(),
      quotes: [],
      quote: {
        "id": 0,
        "author": "",
        "quote": "",
        "isActive": true
      },
      filter: {
        search: "",
        category: "All",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 1
      }
    }
  },
  methods: {
    getMessageByCode,
    editQuote(quote, type) {
      this.quote = JSON.parse(JSON.stringify(quote));
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'del':
          this.deleteDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getQuotes();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getQuotes();
    },
    async getQuotes() {
      this.loading = true;
      try {
        let response = await loginCoreService.getQuotes(this.filter);
        this.quotes = response?.data?.items ?? [];
        this.filter.currentPage = response?.data?.currentPage ?? 1;
        this.filter.pageSize = response?.data?.pageSize ?? 10;
        this.filter.totalCount = response?.data?.totalCount ?? 0;
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Fetching data failed");
      }
      this.loading = false;
    },
    async addQuote() {
      this.loading = true;
      try {
        let response = await loginCoreService.addQuote(this.quote);
        if (response.status === 200) {
          ShowMessage("success", "Quote Added");
          await this.getQuotes();
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Quote adding failed ");
      }
      this.loading = false;
    },
    async updateQuote() {
      this.loading = true;
      try {
        let response = await loginCoreService.updateQuote(this.quote);
        if (response.status === 200) {
          ShowMessage("success", "Quote Updated");
          await this.getQuotes();
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Quote update failed ");
      }
      this.loading = false;
    },
    async deleteQuote(id) {
      this.loading = true;
      try {
        let response = await loginCoreService.deleteQuote(id);
        if (response.status === 200) {
          ShowMessage("success", "Quote Deleted");
          await this.getQuotes();
        }
      } catch (e) {
        ShowMessage("error", e?.response?.data.msg ?? "Quote delete failed ");
      }
      this.loading = false;
    },
    resetIQuote() {
      this.quote = {
        "id": 0,
        "author": "",
        "quote": "",
        "isActive": true
      };
    }
  },
  created() {
    this.getQuotes();
  }
}
</script>