<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" clear-id="customerId"
             :showSelect="false" :ShowAddBtn="false" routeName="agreements"
             :btnText="getMessageByCode('addAgreement') ?? 'Add Agreement'">
      <template v-slot:select>
        <div class="w-100" v-if="clientId === null || clientId === 0" >
          <span class="text-small">{{ getMessageByCode('client') ?? 'Client' }}</span>
          <el-select class="w-100" filterable @change="getTenants()"
                     v-model="filter.clientId">
            <el-option
                v-for="item in clients"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />

          </el-select>
        </div>
      </template>
    </ToolBar>

    <div class="row justify-content-end align-items-center py-1">
      <div class="col-12 col-md-4 d-flex justify-content-end align-items-end">
        <div class="w-100">
          <span class="text-small">{{ getMessageByCode('tenant') ?? 'Tenant' }}</span>
          <el-select v-model="filter.tenantId" filterable class="w-100"
                     placeholder="Select Contact" @change="getAgreements()"
          >
            <el-option
                v-for="item in tenants"
                :key="item.id"
                :label="getTenantName(item)"
                :value="item.id"
            />
          </el-select>
        </div>
        <el-button class="ms-2" @click="addDialog = true;resetAgreement();" type="primary">
          {{ getMessageByCode('add_agree') ?? 'Add Agreement' }}
        </el-button>
      </div>
    </div>
    <el-table :data="agreements" :default-sort="{ prop: 'name', order: 'ascending' }"
              :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe>
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            size="small"
        >
        </el-pagination>
      </template>
      <el-table-column class="text-capitalize" :label="getMessageByCode('tenant') ?? 'Tenant'" prop="tenant"
                       min-width="200"/>
      <el-table-column class="text-capitalize" :label="getMessageByCode('property') ?? 'Property'" prop="property"
                       min-width="200"/>
      <el-table-column class="text-capitalize" :label="getMessageByCode('rent') ?? 'Rent'+ ' ('+currency+')'" prop="rent"
                       min-width="200">
        <template #default="scope">
          <div class="text-end">
            <span>{{ getFormattedValue(scope.row.rent) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column class="text-capitalize" :label="getMessageByCode('commission') ?? 'Commission' + ' ('+currency+')'" prop="commission"
                       min-width="200">
        <template #default="scope">
          <div class="text-end">
            <span>{{ getFormattedValue(scope.row.commission) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" class="text-capitalize"
                       :label="getMessageByCode('actions') ?? 'Actions'" width="130">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <i
                @click="editAgreement(scope.row, 'edit')"
                class="mgc_edit_line text-primary action-btn"

            ></i>

            <i @click="editAgreement(scope.row, 'delete')"
               class="mgc_delete_2_line text-danger action-btn"
            ></i>
          </el-space>
        </template>
      </el-table-column>
    </el-table>


    <!--    Dialogs-->
    <el-dialog v-model="addDialog" style="width: auto; max-width: 700px"
               :title="getMessageByCode('add_agree') ?? 'Add Agreement'" align-center>
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <div class="row">
            <el-form-item v-if="clientId === null || clientId === 0" class="col-12 col-md-6"
                          :label="getMessageByCode('client') ?? 'Client'">
              <el-select v-model="filter.clientId" filterable class="w-100"
                         placeholder="Select" @change="changeClient()"
              >
                <el-option
                    v-for="item in clients"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('tenant') ?? 'Tenant'">
              <el-select v-model="agreement.tenantId" filterable class="w-100"
                         placeholder="Select Tenant"
              >
                <el-option
                    v-for="item in tenants"
                    :key="item.id"
                    :label="getTenantName(item)"
                    :value="item.id"
                    :disabled="item.id < 1"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('property') ?? 'Property'">
              <el-select v-model="agreement.propertyId" filterable class="w-100"
                         placeholder="Select Property"
              >
                <el-option
                    v-for="item in properties"
                    :key="item.id"
                    :label="item.propertyName"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('start_date') ?? 'Start Date'">
              <el-date-picker class="w-100" :format="dateFormat" v-model="agreement.startDate"
                              :editable="false"></el-date-picker>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('end_date') ?? 'End Date'">
              <el-date-picker class="w-100" :format="dateFormat" v-model="agreement.endDate"
                              :editable="false"></el-date-picker>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('rent') ?? 'Rent'">
              <el-input type="number" class="w-100" :value="agreement.rent.toFixed(2)" controls-position="right" v-model="agreement.rent">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('commission') ?? 'Commission'">
              <el-input type="number" class="w-100" :value="agreement.rent.toFixed(2)"  controls-position="right" v-model="agreement.commission">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('security_deposit') ?? 'Security Deposit'">
              <el-input type="number" class="w-100" controls-position="right"
                        :value="agreement.rent.toFixed(2)"   v-model="agreement.securityDeposit">
                <template #suffix>
                  <div v-if="currencyPosition === 'right'">
                    {{ currency }}
                  </div>
                </template>
                <template #prefix>
                  <div v-if="currencyPosition === 'left'">
                    {{ currency }}
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('deposit_day') ?? 'Payment Day'">
              <el-input type="number" class="w-100"  controls-position="right" :max="30" v-model="agreement.paymentDay"
                        @input="validatePaymentDay"></el-input>
              <el-collapse-transition>
                <span v-if="paymentDayError" class="text-small text-danger">Payment day must be between 1 and 30</span>
              </el-collapse-transition>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('leave_date') ?? 'Leave Date'">
              <el-date-picker class="w-100" :format="dateFormat" v-model="agreement.leaveDate"
                              :editable="false"></el-date-picker>
            </el-form-item>
            <el-form-item class="col-12 col-md-6" :label="getMessageByCode('notes') ?? 'Notes'">
              <el-input type="textarea" :rows="3" v-model="agreement.notes"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
          <el-button type="primary" class="ms-2" @click="addDialog = false; addAgreement()"
                     :disabled="agreement.tenantId < 1 || (agreement.paymentDay < 1 || agreement.paymentDay > 30)">
            {{ getMessageByCode('add') ?? 'Add' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
        v-model="deleteDialog"
        :title="getMessageByCode('delete') ?? 'Delete' +' '+ getMessageByCode('agreement') ?? 'Agreement'"
        width="30%"
    >
      <template #header>
        <span>{{ getMessageByCode('delete') ?? ' Delete' }} </span>
        <span> {{ getMessageByCode('agreement') ?? ' Agreement' }}</span>
      </template>
      <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }}</span> <span
        class="text-danger"> selected agreement?</span>

      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteDialog = false">{{
            getMessageByCode('cancel') ?? 'Cancel'
          }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteDialog = false; deleteAgreement()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import tenancyAgreementService from "@/Services/ComponentsServices/tenancyAgreementService";
import contactService from "@/Services/ComponentsServices/contactService";
import {ShowMessage} from "@/Utility/Utility";
import clientService from "@/Services/ComponentsServices/clientService";
import configService from "@/Services/ComponentsServices/configService";
import pmsPropertiesService from "@/Services/ComponentsServices/pmsPropertiesService";

export default {
  name: "TenancyAgreement",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      paymentDayError: false,
      translations: [],
      clients: [],
      dateFormat: "DD/MM/YYYY",
      currency: "£",
      currencyPosition: "left",
      agreement: {
        "id": 0,
        "clientId": "",
        "tenantId": "",
        "startDate": new Date(),
        "endDate": new Date(),
        "propertyId": 0,
        "landlordContactId": 0,
        "rent": 0.00,
        "commission": 0.00,
        "paymentDay": 1,
        "notes": "",
        "securityDeposit": 0,
        "leaveDate": null,
        "isActive": true,
      },
      agreements: [],
      contacts: [],
      tenants: [
        {
          "id": 0,
          "firstName": "All",
          "lastName": "",
        }
      ],
      properties: [],
      clientId: Number(sessionStorage.getItem("clientId")) ?? 0,
      filter: {
        clientId: Number(sessionStorage.getItem("clientId") ?? 0),
        contactId: 0,
        tenantId: 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
        clientGuid: 0,
        category: 0,
        tenantType: 1
      },
    }
  },
  methods: {
    getFormattedValue(value){
      return value.toFixed(value);
    },
    editAgreement(agreement, type) {
      this.agreement = JSON.parse(JSON.stringify(agreement));
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
      }
    },
    getTenantName(item){
      return item.firstName+' '+ item.surname;
    },
    async changeClient() {
      this.agreement.clientId = Number(this.filter.clientId);
      await this.getTenants();
      await this.getFreeProperties()
    },
    validatePaymentDay() {
      this.paymentDayError = this.agreement.paymentDay > 31;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
    },
    async getAgreements() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.getTenantAgreements(this.filter);
        this.agreements = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async addAgreement() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.addAgreement(this.agreement);
        if (response?.data !== null) {
          ShowMessage("success", "Agreement Added");
          await this.getAgreements();
          await this.getFreeProperties();
        }

      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getContacts() {
      this.loading = true;
      try {
        this.filter.contactId = "";
        let response = await contactService.all(this.filter);
        this.contacts = response?.data?.items ?? [];
        if (this.contacts.length > 0) {
          this.agreement.contactId = this.contacts[0].id;

        } else {
          this.agreement.contactId = "";
        }
      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getClients() {
      try {
        this.loading = true;
        if (Number(sessionStorage.getItem('clientId')) === null || Number(sessionStorage.getItem('clientId')) === 0) {
          let response = await clientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            this.filter.clientGuid = this.clients[0].clientGuid;
            this.filter.clientId = this.clients[0].id;
            this.agreement.clientId = this.clients[0].id;
          }
        }
        await this.getTenants();
        await this.getFreeProperties();
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getTenants() {
      try {
        let response = await contactService.getAllContacts(this.filter.clientId, 1);
        this.tenants = [
          {
            "id": 0,
            "firstName": "All",
            "surname": "",
          }
        ];
        if (response?.data?.items?.length > 0) {
          this.tenants = this.tenants.concat(response?.data?.items ?? []);
        }
        if (this.tenants.length > 0) {
          this.agreement.tenantId = this.tenants.length > 1 ? this.tenants[1].id : this.agreement.id = 0;
          this.filter.tenantId = this.tenants[0].id;
        } else {
          this.agreement.tenantId = "";
        }
        await this.getAgreements();
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    async getFreeProperties() {
      try {

        let response = await pmsPropertiesService.getFreeProperties(this.filter.clientId);
        this.properties = response?.data ?? [];
        if (this.properties.length > 0) {
          this.agreement.propertyId = this.properties[0].id;
        } else {
          this.agreement.propertyId = ""
        }
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    async deleteAgreement() {
      this.loading = true;
      try {
        let response = await tenancyAgreementService.deleteAgreement(this.agreement.id);
        if (response.status === 200) {
          ShowMessage("success", "Agreement Deleted Successfully");
          await this.getAgreements();
        }

      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response.data.message);
      }
      this.loading = false;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }
        let response = await configService.getByKey(id, "CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey(id, "CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetAgreement() {
      this.agreement = {
        "id": 0,
        "clientId": this.clients[0].id ?? this.clientId,
        "contactId": 0,
        "tenantId": this.tenants[1].id ?? "",
        "startDate": new Date(),
        "endDate": new Date(),
        "propertyId": this.properties[0].id,
        "landlordContactId": 0,
        "rent": 0,
        "commission": 0,
        "paymentDay": 1,
        "notes": "",
        "securityDeposit": 0,
        "leaveDate": null,
        "isActive": true,
      };
    }
  },
  created() {
    this.getClients();
    this.getConfigs();
  }
}

</script>

<style scoped>

</style>