<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row py-2 align-items-center">
        <div class="col-12 col-md-8">
          <BreadCrumb :view="edit" :id="ClientViewModel.client.id" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="clients">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-else @click="checkClient()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
          </el-button>
          <el-button v-if="clientEdit && edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            <span class="text-capitalize">{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button @click="AddClient" v-if="ClientViewModel.client.id < 1 && clientCreate"
                     class="border-0 mbtn-primary ms-2"><span
              class="text-capitalize">{{ getMessageByCode('add_client') ?? 'Add Client' }}</span>
          </el-button>
          <el-button @click="UpdateClient" v-if="ClientViewModel.client.id > 0 && clientEdit && !edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('update_client') ?? 'Update Client' }}
          </el-button>
        </div>
      </div>
    </div>

    <div class="container-fluid m-0 p-0">
      <el-tabs type="border-card" class="demo-tabs" v-model="activeTab">
        <el-tab-pane :name="1">
          <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-person-vcard me-1"></i>
           <span class="text-capitalize">{{ getMessageByCode('details') ?? 'Details' }}</span>
        </span>
          </template>
          <el-form ref="clientForm" label-position="top" hide-required-asterisk :model="ClientViewModel.client">

            <div class="row my-row">
              <div class="col-12 col-md-4">
                <div class="card  border-0 shadow-sm  h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize">{{ getMessageByCode('company') ?? 'Company' }}</span>
                    <span class="bi bi-building"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item prop="name" :rules="formRules.nameRule">
                      <template #label>
                        <span class="required text-capitalize">{{ getMessageByCode('name') ?? 'Name' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="ClientViewModel.client.name"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item prop="language">
                      <template #label>
                        <span class="required text-capitalize">{{ getMessageByCode('language') ?? 'Language' }}</span>
                      </template>
                      <el-select v-model="ClientViewModel.client.iso" :disabled="edit" filterable
                                 placeholder="Select"
                                 class="w-100">
                        <el-option
                            v-for="item in languages"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value"
                            style="font-weight: normal; color: #0a0a0a"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('telephone') ?? 'Telephone' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="ClientViewModel.client.mobile"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item prop="email" :rules="formRules.emailRule">
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('email') ?? 'Email' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          @keydown.space.prevent
                          v-model="ClientViewModel.client.email"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('website') ?? 'Website' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="ClientViewModel.client.website"
                          type="text"

                      />
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card  border-0 shadow-sm h-100 ">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize">{{ getMessageByCode('address') ?? 'Address' }}</span>
                    <span class="bi bi-sign-turn-right"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 1</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="ClientViewModel.client.address1"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 2</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="ClientViewModel.client.address2"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('address') ?? 'Address' }} 3</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="ClientViewModel.client.address3"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('city') ?? 'City' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="ClientViewModel.client.city"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('postcode') ?? 'Postcode' }}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="ClientViewModel.client.postCode"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('country') ?? 'Country' }}</span>
                      </template>
                      <el-select v-model="ClientViewModel.client.county" :disabled="edit" filterable
                                 placeholder="Select"
                                 class="w-100">
                        <el-option
                            v-for="item in Countries"
                            :key="item.optId"
                            :label="item.optTitle"
                            :value="item.optTitle"
                            style="font-weight: normal; color: #0a0a0a"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card  border-0 shadow-sm  h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize"
                          style="font-size: 17px">{{
                        getMessageByCode('admin_def_login') ?? 'Admin Default Login'
                      }}</span>
                    <span class="bi bi-building"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{ getMessageByCode('disp_name') ?? 'Display Name' }}</span>
                      </template>
                      <el-input
                          :disabled="ClientViewModel.client.id > 0"
                          v-model="ClientViewModel.client.displayName"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item prop="userEmail" :rules="formRules.userEmail">
                      <template #label>
                        <span
                            class="text-capitalize">{{
                            getMessageByCode('username') ?? 'Username'
                          }} / {{ (getMessageByCode('username')) ?? '(email)' }}</span>
                      </template>
                      <el-input
                          :disabled="ClientViewModel.client.id > 0"
                          @keydown.space.prevent
                          v-model="ClientViewModel.client.userEmail"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item prop="password">
                      <template #label>
                        <span
                            class="text-capitalize">{{
                            getMessageByCode('password') ?? 'Password'
                          }} <span v-if="!passwordExists" class="text-small text-lowercase">(default password: client123)</span></span>
                      </template>
                      <el-input
                          :disabled="edit"
                          @keydown.space.prevent
                          v-model="clientPassword"
                          type="text"

                      />
                    </el-form-item>
                  </div>
                </div>
                <div class="card  border-0 shadow-sm mt-3 h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0 text-capitalize"
                          style="font-size: 17px">{{ getMessageByCode('app_config') ?? 'App Configuration' }}</span>
                    <span class="bi bi-phone"></span>

                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form>
                      <el-form-item label-position="top">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <span class="text-capitalize">{{
                                getMessageByCode('is_allowed') ?? 'Is App Allowed'
                              }}</span>
                            <el-switch
                                :disabled="edit"
                                inline-prompt
                                active-text="Yes"
                                inactive-text="No" v-model="ClientViewModel.client.isAppAllowed"></el-switch>

                          </div>
                        </template>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </el-form>

        </el-tab-pane>
        <el-tab-pane :name="2">
          <template #label>
            <div class="d-flex">
              <i class="bi bi-card-checklist me-1"></i>
              <span class="text-capitalize">{{ getMessageByCode('license') ?? 'License' }}</span>
            </div>
          </template>
          <el-form ref="clientForm" :model="ClientViewModel.client">
            <div class="row">
              <div class="col-12 col-md-4">
                <label class="lblText text-capitalize">{{ getMessageByCode('market') ?? 'Market' }}</label>
                <el-form-item prop="name">
                  <el-select v-model="ClientViewModel.client.marketId" class="w-100" @change="getPackages"
                             :disabled="edit">
                    <el-option v-for="market in Markets" :key="market.id" :label="market.title"
                               :value="market.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText text-capitalize">{{ getMessageByCode('package') ?? 'Package' }}</label>
                <el-form-item prop="name">
                  <el-select v-model="ClientViewModel.client.packageId" class="w-100" :disabled="edit"
                             @change="getModules()">
                    <el-option v-for="pkg in Packages" :key="pkg.id" :label="pkg.title"
                               :value="pkg.id"
                               style="font-weight: normal; color: #0a0a0a"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-12 col-md-8">
                <el-table :data="ClientViewModel.packageModuleClient" border header-cell-class-name="tbl-header"
                          stripe>
                  <el-table-column type="index" width="55"></el-table-column>
                  <el-table-column label="Title" prop="moduleTitle"></el-table-column>
                  <!--                  <el-table-column label="Slug" prop="moduleSlug"></el-table-column>-->
                  <el-table-column label="Default Type" prop="defaultType"></el-table-column>
                  <el-table-column label="Default Value" prop="defaultValue">
                    <template #default="scope">
                      <el-input v-model="scope.row.defaultValue" :disabled="edit"></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :name="3" v-if="ClientViewModel.client.id > 0">
          <template #label>
            <div class="d-flex">
              <i class="bi bi-lock-fill me-1"></i>
              <span class="text-capitalize">{{ getMessageByCode('status') ?? 'Status' }}</span>
            </div>
          </template>
          <div class="row">
            <div class="col-12">
              <div v-if="ClientViewModel.client.isActive">
                <div class="text-caption">
                  <span
                      class="text-capitalize">{{
                      getMessageByCode('client') ?? 'Client'
                    }} {{ getMessageByCode('status') ?? 'Status' }}:</span>
                  <span v-if="ClientViewModel.client.isActive" class="text-success mfw-bold ">
                  {{ getMessageByCode('active') ?? 'Active' }}
                </span>
                </div>
                <el-button type="primary" class="mt-2" @click="disableClient()"><span
                    class="text-capitalize">{{ getMessageByCode('dis_client') ?? 'Disable Client' }}</span></el-button>
                <span class="text-caption d-block text-capitalize">{{ getMessageByCode('disclaimer') ?? 'Disclaimer' }}: {{
                    getMessageByCode('disc_note_dis') ?? 'Disabling client will make all users inactive and client will not be able to login'
                  }}</span>
              </div>
              <div v-else>
                <div class="text-caption">
                  <span
                      class="text-capitalize">{{
                      getMessageByCode('client') ?? 'Client'
                    }} {{ getMessageByCode('status') ?? 'Status' }}:</span>
                  <span class="text-danger mfw-bold ">
                  {{ getMessageByCode('inactive') ?? 'InActive' }}
                </span>
                </div>
                <el-button type="primary" class="mt-2" @click="enableClient()"><span
                    class="text-capitalize">{{ getMessageByCode('ena_client') ?? 'Enable Client' }}</span></el-button>
                <span
                    class="text-caption d-block text-capitalize">{{ getMessageByCode('disclaimer') ?? 'Discalaimer' }}: {{
                    getMessageByCode('disc_note_en') ?? 'Enabling client will make all users active and client will be able to login'
                  }}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :name="4" v-if="ClientViewModel.client.id > 0">
          <template #label>
            <div class="d-flex">
              <i class="bi bi-people me-1"></i>
              <span class="text-capitalize">{{ getMessageByCode('users') ?? 'Users' }} </span>
            </div>
          </template>
          <div class="row">
            <div class="col-12">
              <el-table :data="clientUsers" :fit="true" :flexible="true"
                        :selectable="(row) => row.status !== 'disabled'" border
                        header-cell-class-name="tbl-header"
                        stripe>
                <template #append>
                  <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :total="filter.totalCount"
                      :page="filter.currentPage"
                      :page-size="filter.pageSize"
                      :page-sizes="[10, 20, 30, 40]"
                      layout=" prev, pager, next,sizes"
                      class="bg-gray p-2"
                      background
                      size="small"
                  >
                  </el-pagination>
                </template>
                <el-table-column :label="getMessageByCode('name') ?? 'Name'" prop="displayName">
                  <template #default="scope">
                    <span class="router-text" @click="editUser(scope.row)">{{
                        scope.row.displayName
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="getMessageByCode('email') ?? 'Email'" prop="email"></el-table-column>
                <el-table-column :label="getMessageByCode('mobile') ?? 'Mobile'" prop="mobile"></el-table-column>
                <!--                <el-table-column label="Active" prop="isActive" width="80" align="center">-->
                <!--                  <template #default="scope">-->
                <!--                    <el-tag size="small" class="btn-s-secondary" v-if="scope.row.isActive">Yes</el-tag>-->
                <!--                    <el-tag size="small" class="btn-d-secondary" v-else>No</el-tag>-->
                <!--                  </template>-->

                <!--                </el-table-column>-->
                <!--                <el-table-column label="Action" prop="action" width="80">-->
                <!--                  <template #default="scope">-->
                <!--                    <i class="mgc_forbid_circle_line text-warning fs-5 ms-1 action-btn" v-if="!scope.row.isActive"-->
                <!--                       @click="scope.row.isActive = !scope.row.isActive"></i>-->
                <!--                    <i class="mgc_check_circle_line text-success fs-5 ms-1 action-btn" v-if="scope.row.isActive"-->
                <!--                       @click="scope.row.isActive = !scope.row.isActive"></i>-->
                <!--                    <i class="mgc_delete_2_line text-danger fs-5 ms-1 action-btn"-->
                <!--                       @click="scope.row.isActive = false"></i>-->
                <!--                  </template>-->
                <!--                </el-table-column>-->
              </el-table>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :name="5" v-if="ClientViewModel.client.id > 0 && ClientViewModel.client.isAppAllowed">
          <template #label>
            <div class="d-flex">
              <i class="bi bi-braces-asterisk me-1"></i>
              <span class="text-capitalize">{{ getMessageByCode('authCodes') ?? 'Auth Codes' }} </span>
            </div>
          </template>
          <div class="row">
            <div class="col-12">
              <div class="row justify-content-end py-1">
                <div class="col-12 col-lg-4">
                  <div class="d-flex justify-content-end">
                    <el-input v-model="authCode.authCode" @keydown.space.prevent
                              placeholder="Enter AuthCode to add" clearable @clear="resetAuthCode()"></el-input>
                    <el-button v-if="authCode.id < 1" class="ms-2" type="primary" :disabled="!authCode.authCode"
                               @click="addAuthCode()">
                      {{ getMessageByCode('addCode') ?? 'Add Code' }}
                    </el-button>
                    <el-button v-else class="ms-2" type="primary" :disabled="!authCode.authCode"
                               @click="updateAuthCode()">
                      {{ getMessageByCode('updateCode') ?? 'Update Code' }}
                    </el-button>
                  </div>
                </div>
              </div>
              <el-table :data="codes" :fit="true" :flexible="true"
                        :selectable="(row) => row.status !== 'disabled'" border
                        header-cell-class-name="tbl-header"
                        stripe>
                <template #append>
                  <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :total="filter.totalCount"
                      :page="filter.currentPage"
                      :page-size="filter.pageSize"
                      :page-sizes="[10, 20, 30, 40]"
                      layout=" prev, pager, next,sizes"
                      class="bg-gray p-2"
                      background
                      size="small"
                  >
                  </el-pagination>
                </template>
                <el-table-column :label="getMessageByCode('authCode') ?? 'Auth Code'" prop="authCode">
                </el-table-column>
                <el-table-column :label="getMessageByCode('expiryDate') ?? 'Expiry Date'" prop="expiryDate">
                  <template #default="scope">
                    <span>{{ getFormattedDate(scope.row.expiryDate) }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="100" :label="getMessageByCode('valid') ?? 'Valid' + '?'" prop="authCode">
                  <template #default="scope">
                    <el-tag size="small" v-if="scope.row.isValid" type="success" class="px-3">Valid</el-tag>
                    <el-tag size="small" v-else type="warning" class="px-2">InValid</el-tag>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" width="120" :label="getMessageByCode('action') ?? 'Action'"
                                 prop="authCode">
                  <template #default="scope">
                    <div class="d-flex justify-content-center">
                      <i class="action-btn mgc_edit_line text-primary"
                         @click="this.authCode = JSON.parse(JSON.stringify(scope.row))"></i>
                      <div v-if="scope.row.isValid">
                        <el-tooltip :content="getMessageByCode('disable') ?? 'Disable'">
                          <i class="action-btn mgc_forbid_circle_line text-warning ms-2"
                             @click="disableAuthCode(scope.row.id)"></i>
                        </el-tooltip>
                      </div>
                      <div v-else>
                        <el-tooltip :content="getMessageByCode('enable') ?? 'Enable'">
                          <i class="action-btn mgc_check_circle_line text-success ms-2"
                             @click="enableAuthCode(scope.row.id)"></i>
                        </el-tooltip>
                      </div>
                      <i class="action-btn mgc_delete_2_line text-danger action-btn ms-2 "
                         @click="deleteAuthCode(scope.row.id)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="Update User" v-model="updateUserDialog" width="600">
      <el-form :model="User" hide-required-asterisk label-position="top">
        <div class="row">
          <!--          <div class="col-12 col-md-6" v-if="this.clientId == null">
                      <el-form-item>
                        <template #label>
                          <span class="">Client</span>
                        </template>
                        <el-select :disabled="clientId ?? null" v-model="User.clientId" filterable class="w-100"
                                   placeholder="Select"
                        >
                          <el-option
                              v-for="item in Clients"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </div>-->
          <div class="col-12 col-md-6">
            <el-form-item>
              <template #label>
                <span class="required">Full Name</span>
              </template>
              <el-input v-model="User.displayName"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item>
              <template #label>
                <span class="required">Username</span>

              </template>
              <el-input v-model="User.userName"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item>
              <template #label>
                <span class="required">Email</span>
              </template>
              <el-input v-model="User.email"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item>
              <template #label>
                <span class="">Phone</span>
              </template>
              <el-input v-model="User.mobile"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6 d-flex justify-content-between">
            <el-form-item>
              <template #label>
                <span class="">Active</span>
              </template>
              <el-switch active-text="Yes" inactive-text="No" v-model="User.isActive"></el-switch>
            </el-form-item>

          </div>
          <div class="col-12 col-md-6 d-flex justify-content-between">
            <el-form-item>
              <template #label>
                <span class="">Email Notification</span>
              </template>
              <el-switch active-text="Yes" inactive-text="No" v-model="User.isEmailNotification"></el-switch>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="updateUserDialog = false" class="me-2">Cancel</el-button>
          <el-button type="primary" @click="updateUserDialog = false">Update User</el-button>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import {ShowMessage} from "@/Utility/Utility";
import store from "@/store";
import ClientService from "@/Services/ComponentsServices/clientService";
import optionService from "@/Services/ComponentsServices/optionService";
import {checkPermission} from "@/Utility/CheckPermission";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import marketService from "@/Services/ComponentsServices/marketService";
import packageService from "@/Services/ComponentsServices/packageService";
import userService from "@/Services/ComponentsServices/userService";
import {getLanguage} from "@/Utility/getLanguage";
import moment from "moment/moment";
import clientService from "@/Services/ComponentsServices/clientService";

export default {
  name: "AddClient",
  components: {BreadCrumb},
  data() {
    return {
      activeTab: 1,
      mainBtnText: "Add Client",
      updateUserDialog: false,
      clientId: sessionStorage.getItem("clientId"),
      User: {},
      addDisabled: false,
      clientEdit: false,
      edit: true,
      passwordExists: false,
      clientCreate: false,
      loading: false,
      Countries: [],
      clientUsers: [],
      Packages: [],
      Markets: [],
      codes: [],
      PackageModules: [],
      PackageModulesClient: [],
      clientPassword: "",
      formRules: {
        nameRule: [
          {required: true, message: 'Please enter your name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        emailRule: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ], userEmail: [
          {required: true, message: 'Please enter the user email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid user email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
      },
      translations: [],
      languages: [
        {text: "English", value: "en"},
        {text: "French", value: "fr"},
        {text: "Arabic", value: "ar"},
      ],
      ClientViewModel: {

        "client": {
          "id": store.state.pageId,
          "name": "",
          "iso": "en",
          "email": "",
          "mobile": "",
          "website": "",
          "address1": "",
          "address2": "",
          "address3": "",
          "postCode": "",
          "city": "",
          "county": "",
          "displayName": "",
          "userEmail": "",
          "password": "",
          "isActive": true,
          "marketId": 0,
          "packageId": 0,
          "isAppAllowed": false,
        },
        "packageModuleClient": []
      },
      filter: {
        clientId: 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
        guid: ""
      },
      dateFormat: "DD/MM/YYYY",
      authCode: {
        "id": 0,
        "clientGuid": "",
        "authCode": "",
        "expiryDate": null,
        "isValid": true
      }
    }
  },
  methods: {
    getFormattedDate(date) {
      if (date === null) return "";
      return moment(date).format(this.dateFormat)
    },
    checkClient() {
      if (this.ClientViewModel.client.id < 1) {
        this.$router.push('clients');
        return;
      }
      this.edit = !this.edit
    },
    editUser(user) {
      this.User = {...user};
      this.updateUserDialog = true;
    },
    validateEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback(new Error('Please enter a valid email address'));
      }
    },
    AddOrUpdateClient() {
      switch (this.ClientViewModel.client.id) {
        case 0:
          this.AddClient();
          break;
        default:
          this.UpdateClient();
          break;
      }
    },
    async enableClient() {
      try {

        // eslint-disable-next-line no-unused-vars
        let response = await ClientService.activate(this.ClientViewModel.client.id);
        this.$store.state.pageId = this.ClientViewModel.client.id;
        await this.getClient();

      } catch (e) {
        console.log(e)
      }
    },
    async disableClient() {
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await ClientService.deactivate(this.ClientViewModel.client.id);
        this.$store.state.pageId = this.ClientViewModel.client.id;

        await this.getClient();
      } catch (e) {
        console.log(e)
      }
    },
    async AddClient() {
      this.loading = true;
      if (this.ClientViewModel.client.name === "") {
        ShowMessage("error", "Please enter the required fields");
        return;
      }
      this.$refs.clientForm.validate(async (valid) => {
        if (valid) {
          try {

            if (!this.ClientViewModel.client.password && !this.clientPassword) {
              this.ClientViewModel.client.password = 'client123'
            }
            if(this.clientPassword){
              this.ClientViewModel.client.password = this.clientPassword;
            }
            // eslint-disable-next-line no-unused-vars
            let response = await ClientService.addClient(this.ClientViewModel);

            ShowMessage("success", "Client add successful");
            this.loading = false;
          } catch (e) {
            ShowMessage("error", e.response.data.message);
            this.loading = false;
          }
        } else {
          ShowMessage("error", "Please enter the required fields!");
          this.loading = false;
        }
      });
      this.loading = false;
    },
    async UpdateClient() {
      this.loading = true;
      this.$refs.clientForm.validate(async (valid) => {
        if (valid) {
          try {
            // eslint-disable-next-line no-unused-vars
            let response = await ClientService.updateClient(this.ClientViewModel.client);

            ShowMessage("success", "Client updated successfully");
            this.loading = false;
          } catch (e) {
            console.log(e)
            ShowMessage("error", "Client update failed");
            this.loading = false;
          }
        } else {
          ShowMessage("error", "Please enter the required fields!")

        }
      });
    },
    async getClient() {
      try {
        this.loading = true;
        let response = await ClientService.getbyId(this.ClientViewModel.client.id);
        this.ClientViewModel = response.data ?? {};
        this.passwordExists = !!this.ClientViewModel.client.password;
        if (this.ClientViewModel.client.id > 0) {
          this.filter.clientId = this.ClientViewModel.client.id;
          this.filter.guid = this.ClientViewModel.client.clientGuid;
          await this.getClientUsers();
          if (this.ClientViewModel.client.isAppAllowed) {
            await this.getAuthCodes();
          }
        }
        this.loading = false;
      } catch {
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },
    async getClientUsers() {
      try {
        let response = await userService.all(this.filter);
        this.clientUsers = response.data.items ?? []
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
        // eslint-disable-next-line no-empty
      } catch {

      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getClientUsers();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getClientUsers();
    },
    async getCountries() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByHeaderId(0, 7)
        this.Countries = response.data ?? {};
        if (this.Countries.length > 0) {
          if (this.ClientViewModel.client.county === "") {
            this.ClientViewModel.client.county = this.Countries[0].optTitle;
          }
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },
    async getMarkets() {
      try {
        let response = await marketService.getAll();
        this.Markets = response.data.items ?? [];
        if (this.Markets.length > 0) {
          this.ClientViewModel.client.marketId = this.Markets[0].id;
          await this.getPackages();
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getPackages() {
      try {
        let response = await packageService.getAll(this.ClientViewModel.client.marketId);
        this.Packages = response.data.items ?? [];
        if (this.Packages.length > 0) {
          this.ClientViewModel.client.packageId = this.Packages[0].id;

          await this.getModules(this.ClientViewModel.client.packageId);
        } else {
          this.Packages = [];
          this.PackageModules = [];
          this.ClientViewModel.client.packageId = ""
        }
      } catch (e) {

        console.log(e)
      }
    },
    async getModules() {
      try {
        // if(type === 'change',this.ClientViewModel.client.marketId < 1){
        //   ShowMessage("error", "Select a market firest");
        //   return;
        // }
        this.loading = true;
        let response = await packageService.getModules(this.ClientViewModel.client.packageId)
        this.PackageModules = response.data ?? [];

        if (this.PackageModules.length > 0 && this.ClientViewModel.client.marketId > 0) {
          if (this.ClientViewModel.packageModuleClient.length < 1 || this.ClientViewModel.packageModuleClient[0].clientId === null || this.ClientViewModel.packageModuleClient[0].packageId !== this.PackageModules[0].id) {
            this.ClientViewModel.packageModuleClient = this.PackageModules;
          }
        }
        /*  this.ClientViewModel.packageModuleClient.forEach(e => {
            e.moduleId = e.id;
            e.id = 0
          });*/
        this.loading = false;
      } catch {
        this.PackageModules = [];
        this.loading = false;
      }
    },
    async updateUser() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.update(this.User);
        if (response.status === 200) {
          ShowMessage("success", "User updated");
          this.addUserDialog = false;
          await this.getClientUsers();
        }
        this.loading = false;

      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.")
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async deleteAuthCode(id) {
      this.loading = true;
      try {
        let response = await clientService.deleteAuthCode(id);
        console.log(response);
        if (response?.data) {
          await this.getAuthCodes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code deletion failed")
      }
      this.loading = false;
    },
    async getAuthCodes() {
      this.loading = true;
      try {
        let response = await clientService.getAuthCodes(this.filter);
        this.codes = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async enableAuthCode(id) {
      this.loading = true;
      try {
        let response = await clientService.activateAuthCode(id);
        if (response?.data) {
          await this.getAuthCodes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code enable failed")
      }
    },
    async disableAuthCode(id) {
      this.loading = true;
      try {
        let response = await clientService.deActivateAuthCode(id);
        if (response?.data) {
          await this.getAuthCodes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code disable failed")
      }
    },
    async addAuthCode() {
      this.loading = true;
      try {
        this.authCode.clientGuid = this.ClientViewModel.client.clientGuid;
        let response = await clientService.addAuthCode(this.authCode);
        this.codes.push(response?.data);
        this.resetAuthCode();
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code adding failed")
      }
      this.loading = false;
    },
    async updateAuthCode() {
      this.loading = true;
      try {
        let response = await clientService.updateAuthCode(this.authCode);
        if (response?.data !== null) {
          await this.getAuthCodes();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.ms ?? "Auth code update failed")
      }
      this.loading = false;
    },
    resetAuthCode() {
      this.authCode = {
        "id": 0,
        "clientGuid": "",
        "authCode": "",
        "expiryDate": null,
        "isValid": true
      }
    }
  },
  created() {
    this.translations = getLanguage();
    this.clientEdit = checkPermission("MD_CLIENT_EDIT");
    this.clientCreate = checkPermission("MD_CLIENT_CREATE");
    this.getMarkets();
    this.getCountries();
    if (store.state.pageId > 0) {
      this.mainBtnText = this.getMessageByCode('update_client') ?? 'Update Client';
      this.getClient();
    } else {
      this.edit = false;
      this.mainBtnText = this.getMessageByCode('add_client') ?? 'Add Client';
    }
  }
}
</script>

<style scoped>

</style>