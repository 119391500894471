<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" clear-id="propertyId"
             :showSelect="true"
             :ShowAddBtn="false" routeName="addpmsproperty"
             :btnText="getMessageByCode('add_prop')?? 'Add Property'">
      <template v-slot:select>
        <el-select v-if="clientId == null || Number(clientId) === 0" class="w-100" filterable
                   @change="getEmails();getContacts();this.getPmsProperties(); "
                   v-model="filter.clientId">
          <el-option
              v-for="item in clients"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </template>
    </ToolBar>
    <div>

      <div class="d-flex justify-content-end mb-1">
        <div class="col-12 col-md-4 col-lg-4 mb-1  p-0 m-0 d-flex justify-content-end">
          <el-input v-model="filter.search" class="align-self-end" clearable
                    :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getEmails"
                    @keyup="getEmails">
            <template #append>
              <el-button @click="getEmails">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
          <el-button type="primary" class="ms-2" @click="addEmailDialog = true;resetEmail()">{{
              getMessageByCode('sendEmail') ?? 'Send Email'
            }}
          </el-button>
        </div>
      </div>
      <el-table :data="emails"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe>
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              size="small"
          >
          </el-pagination>
        </template>
        <el-table-column width="200" :label="getMessageByCode('property')??'Property'"
                         prop="property">
          <template #default="scope">
            <span>{{ scope.row.property }}</span>
            <i v-if="scope.row.propertyId > 0 " class="ms-2 bi bi-link-45deg text-primary action-btn" @click="goToProperty(scope.row.propertyId)"></i>
          </template>
        </el-table-column>
        <el-table-column width="200" :label="getMessageByCode('sentDate')??'Sent Date'"
                         prop="sentDate">
          <template #default="scope">
            <span>{{ getEmailFormattedDate(scope.row.sentDate) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200" :label="getMessageByCode('to')??'To'"
                         prop="to">
          <template #default="scope">
            <span>{{ scope.row.toName }}</span>
            <n-popover trigger="hover">
              <template #trigger>
                <i class="bi bi-envelope ms-2 action-btn text-primary"></i>
              </template>
              <span>{{ scope.row.to }}</span>
            </n-popover>
          </template>
        </el-table-column>
        <el-table-column :label="getMessageByCode('contactType')??'Contact Type'"
                         prop="contactType"></el-table-column>

        <el-table-column :label="getMessageByCode('subject')??'Subject'"
                         prop="subject"></el-table-column>

        <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
          <template #default="scope">
            <el-space alignment="center" spacer="|">
              <i
                  class="mgc_eye_2_line text-primary action-btn"
                  @click="editEmail(scope.row,'edit')"
              ></i>
              <i @click="editEmail(scope.row,'delete')"
                 class="mgc_delete_2_line text-danger action-btn"
              ></i>
            </el-space>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    Dialogs-->
    <el-dialog v-model="addEmailDialog" align-center
               :title="email.id > 0 ?  getMessageByCode('viewEmail') ?? 'View Email' :  getMessageByCode('sendEmail') ?? 'Send Email'"
               style="width: auto; max-width: 670px">
      <el-form label-position="top" require-asterisk-position="right" class="row">
        <el-form-item class="col-12 col-md-6" :label="getMessageByCode('property') ?? 'Property'">
          <el-select :disabled="email.id > 0" v-model="email.propertyId" clearable @clear="email.propertyId = ''">
            <el-option v-for="item in properties"
                       :key="item.id"
                       :label="item.propertyName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required class="col-12 col-md-6" :label="getMessageByCode('to') ?? 'To'">
          <el-select :disabled="email.id > 0" v-model="email.emailTo">
            <el-option v-for="item in contacts"
                       :key="item.id"
                       :label="getTenantName(item)"
                       :value="item.id">
              <template #default>
                <div class="row">
                  <span class="col-7">{{getTenantName(item)}}</span>
                  <span class="text-small col-5">{{ item.category}}</span>
                </div>
              </template>

            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('cc') ?? 'CC'">
          <el-input :disabled="email.id > 0" v-model="email.copyTo"></el-input>
        </el-form-item>
        <el-form-item required :label="getMessageByCode('subject') ?? 'Subject'">
          <el-input :disabled="email.id > 0" v-model="email.subject" :maxlength="250" show-word-limit></el-input>
        </el-form-item>
        <el-form-item :disabled="email.id > 0" required :label="getMessageByCode('emailBody') ?? 'Email Body'">
          <ckeditor :disabled="email.id > 0" :editor="editor" class="w-100" v-model="email.body" :config="editorConfig"
                    style="width: 100% !important; min-height: 200px !important;"></ckeditor>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <div v-if="email.id < 1">
            <el-button text bg class="ms-2" @click="addEmailDialog = false">Cancel</el-button>
            <el-button type="primary" class="ms-2"
                       :disabled="email.emailTo < 1 || !email.subject || !email.body"
                       @click="addEmailDialog = false;addEmail()">
              {{
                getMessageByCode('sendEmail') ?? 'Send Email'
              }}
            </el-button>
          </div>
          <el-button v-else type="primary" class="ms-2"
                     @click="addEmailDialog = false">
            {{
              getMessageByCode('close') ?? 'Close'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteEmailDialog"
               :title="getMessageByCode('deleteEmail') ?? 'Delete Email'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold ">selected email?</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteEmailDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteEmailDialog = false; deleteEmail()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import {getLanguage} from "@/Utility/getLanguage";
import contactService from "@/Services/ComponentsServices/contactService";
import pmsEmailService from "@/Services/ComponentsServices/pmsEmailService";
import {ShowMessage} from "@/Utility/Utility";
import ClientService from "@/Services/ComponentsServices/clientService";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import configService from "@/Services/ComponentsServices/configService";
import pmsPropertiesService from "@/Services/ComponentsServices/pmsPropertiesService";
import {NPopover} from "naive-ui";
import moment from "moment";

export default {
  name: "PmsEmails",
  components: {NPopover, ToolBar, ckeditor: CKEditor.component},
  data() {
    return {
      clientId: sessionStorage.getItem("clientId"),
      loading: false,
      addEmailDialog: false,
      deleteEmailDialog: false,
      translations: [],
      clients: [],
      emails: [],
      contacts: [],
      properties: [],
      dateFormat: "DD/MM/YYYY",
      fromEmail: "",
      email: {
        "id": 0,
        "emailFrom": 0,
        "emailTo": 0,
        "copyTo": "",
        "subject": "",
        "body": "",
        "propertyId": "",
        "clientId": 0,
         "sentDate": new Date()
      },
      editor: ClassicEditor,
      editorConfig: {
        height: 500
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        propertyId: 0,
        tenantId: 0,
      },
    }
  },
  methods: {
    goToProperty(propertyId) {
      this.$store.state.pageId = propertyId;
      this.$router.push('/addpmsproperty')
    },
    getEmailFormattedDate(date) {
      return moment(date).format(this.dateFormat + " hh:mm A")
    },
    editEmail(email, type) {
      this.email = JSON.parse(JSON.stringify(email));
      switch (type) {
        case 'edit':
          this.addEmailDialog = true;
          break;
        case 'delete':
          this.deleteEmailDialog = true;
          break
      }
    },
    getTenantName(item) {
      return item.firstName + ' ' + item.surname;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getProperties();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getProperties();
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          this.$store.state.loading = true;
          let response = await ClientService.getAll();
          this.clients = response.data.items;
          if (this.clients.length > 0) {
            this.filter.clientId = this.clients[0].id;
          }
        }

      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
      await this.getEmails();
      await this.getContacts();
      await this.getPmsProperties();
    },

    // Emails

    async getEmails() {
      this.loading = true;
      try {

        let response = await pmsEmailService.getEmails(this.filter);
        this.emails = response?.data?.items ?? [];
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async addEmail() {
      this.loading = true;
      try {
        if (this.email.emailTo < 1) {
          ShowMessage("warning", "Please select a recipient first");
          return;
        }
        if (!this.email.subject || !this.email.body) {
          ShowMessage("warning", "Subject and body are mandatory");
          return;
        }
        if(this.email.propertyId < 1){
          this.email.propertyId = 0
        }
        this.email.clientId = this.filter.clientId ?? 0;
        this.email.emailFrom = this.fromEmail;
        if (this.email.propertyId < 1) {
          this.email.propertyId = 0;
        }
        let response = await pmsEmailService.addEmail(this.email);
        if (response.status === 200) {
          ShowMessage("success", "Email added");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email adding failed, try again later");
      }
      this.loading = false;
    },
    async updateEmail() {
      this.loading = true;
      try {
        let response = await pmsEmailService.updateEmail(this.email);
        if (response.status === 200) {
          ShowMessage("success", "Email updated");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email updating failed, try again later");
      }
      this.loading = false;
    },
    async deleteEmail() {
      this.loading = true;
      try {
        let response = await pmsEmailService.deleteEmail(this.email.id);
        if (response.status === 200) {
          ShowMessage("success", "Email deleted");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email deleting failed, try again later");
      }
      this.loading = false;
    },
    async getContacts() {
      try {
        let response = await contactService.getAllContacts(this.filter.clientId, 0);

        this.contacts = response?.data?.items ?? [];
        if (this.contacts.length > 0) {
          this.email.emailTo = this.contacts[0].id;
        } else {
          this.email.emailTo = "";
        }
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },
    resetEmail() {
      this.email = {
        "id": 0,
        "emailFrom": "",
        "emailTo": this.contacts[0]?.id ?? "",
        "copyTo": "",
        "subject": "",
        "body": "",
        "propertyId": "",
        "clientId": 0,
         "sentDate": new Date()
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }

        let response = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response.data.value ?? "DD/MM/YYYY";
        let response2 = await configService.getByKey(id, "FromEmail");
        this.fromEmail = response2.data.value ?? "admin@fdesigns.co.uk";
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getPmsProperties() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.getAllProperties(this.filter.clientId);
        this.properties = response.data.items ?? [];

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
  created() {
    this.getClients();
    this.getConfigs();
  }
}
</script>

<style scoped>

</style>