<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row py-2 align-items-center">
        <div class="col-12 col-md-8">
          <BreadCrumb :id="User.id" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="users">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-else @click="checkUser()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
          </el-button>
          <el-button v-if="userEdit && edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            <span class="text-capitalize">{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button v-if="User.id < 1" @click="addUser()"
                     class="border-0 mbtn-primary ms-2"><span
              class="text-capitalize">{{ getMessageByCode('add_user') ?? 'Add User' }}</span>
          </el-button>
          <el-button @click="updateUser()" v-if="User.id > 0 && userEdit && !edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('upd_user') ?? 'Update User' }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0 pt-5">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <el-form class="w-100" label-position="right" label-width="200px" v-model="User" :rules-="formRules"
                   hide-required-asterisk
                   require-asterisk-position="right">
            <el-form-item prop="userName">
              <template #label>
                <span class="required">Username</span>
              </template>
              <el-input :disabled="edit" v-model="User.userName" @keyup="validateUser(User.userName)"
                        @blur="validateUser(User.userName)"></el-input>
              <el-collapse-transition>
                <div class="d-flex flex-column">
                  <span v-if="validateUsername.required"
                        class="lh-sm text-small text-danger">Please enter the username</span>
                  <span v-if="validateUsername.min" class="lh-sm text-small text-danger">Please must be at least 8 characters</span>
                </div>
              </el-collapse-transition>
            </el-form-item>
            <el-form-item v-if="clientId == null || Number(clientId) === 0" prop="clientId">
              <template #label>
                Client
              </template>
              <el-select placeholder="Select client" @change="getProviders();getRoles()" :disabled="edit" class="w-100"
                         v-model="User.clientId">
                <el-option
                    v-for="item in clients"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    style="font-weight: normal; color: #0a0a0a"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="role">
              <template #label>
               <span class="required">
                  Role
               </span>
              </template>
              <el-select :disabled="edit || (providerId === User.providerId && userId === User.id)" class="w-100"
                         v-model="User.roleName" @change="changeRole(User.roleName)">
                <el-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                    style="font-weight: normal; color: #0a0a0a"
                />
              </el-select>
            </el-form-item>

            <el-form-item v-if="showProvider" prop="providerId">
              <template #label>
                Provider
              </template>
              <el-select placeholder="Select provider" :disabled="edit" class="w-100" v-model="User.providerId">
                <el-option
                    v-for="item in providers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    style="font-weight: normal; color: #0a0a0a"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="displayName">
              <template #label>
                Display Name
              </template>
              <el-input :disabled="edit" v-model="User.displayName"></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <template #label>
                Email
              </template>
              <el-input :disabled="edit" v-model="User.email"></el-input>
            </el-form-item>
            <el-form-item prop="isActive">
              <template #label>
                Is Active
              </template>
              <el-switch :disabled="edit" v-model="User.isActive"></el-switch>
            </el-form-item>
            <el-form-item prop="password">
              <template #label>
                Password
              </template>
              <el-input :disabled="edit" type="password" show-password v-model="User.password"></el-input>
            </el-form-item>
            <!--            <el-form-item prop="confirmPassword" :rules="formRules.confirmPassword">-->
            <!--              <template #label>-->
            <!--                Confirm Password-->
            <!--              </template>-->
            <!--              <el-input v-model="User.confirmPassword"></el-input>-->
            <!--            </el-form-item>-->
          </el-form>
        </div>
      </div>


    </div>

    <div v-if="loading" class="spinner-border dialog-loader"></div>
  </div>
</template>
<script>
import userService from "@/Services/ComponentsServices/userService";
import {ShowMessage} from "@/Utility/Utility";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {getLanguage} from "@/Utility/getLanguage";
import roleService from "@/Services/ComponentsServices/roleService";
import providerService from "@/Services/ComponentsServices/ProviderService";
import store from "@/store";
import {checkPermission} from "@/Utility/CheckPermission";
import ClientService from "@/Services/ComponentsServices/clientService";

export default {
  name: "AddUser",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      clientId: sessionStorage.getItem("clientId"),
      Clients: [],
      roles: [],
      providerId: sessionStorage.getItem("providerId"),
      userId: sessionStorage.getItem("userId"),
      providers: [],
      clients: [],
      cardTitle: "Add User",
      IsAddUser: true,
      translations: [],
      showProvider: false,
      confirmPassword: "",
      formRules: {
        usernameRule: [
          {required: true, message: 'Please enter username', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        confirmPassword: [
          {required: true, message: 'Please confirm your password', trigger: 'blur'},
          {validator: this.validatePassword, trigger: ['blur', 'change']},
        ],
      },
      userCreate: false,
      userEdit: false,
      edit: false,
      validateUsername: {
        required: true,
        min: true,
      },
      User: {
        "id": 0,
        "clientId": sessionStorage.getItem("clientId"),
        "userName": "",
        "role": "",
        "roleName": "",
        "providerId": "",
        "email": "",
        "displayName": "",
        "isActive": true,
        "mobile": "",
        "isEmailNotification": true,
        "image": "",
        "password": "",
        "confirmPassword": "",
        "permissionList":[],
      },
    }
  },
  watch: {},
  methods: {
    validateUser(name) {
      this.validateUsername.required = !name; // true if name is empty, false otherwise
      this.validateUsername.min = name.length <= 8;
    },
    checkUser() {
      if (this.User.id < 1) {
        this.$router.push('users');
        return;
      }
      this.edit = !this.edit
    },
    validatePassword(rule, value, callback) {
      if (value === this.User.password) {
        callback();
      } else {
        callback(new Error('Password does not match'));
      }
    },
    changeRole(role) {
      if (role.toString().trim().toLowerCase() === "Provider".toString().trim().toLowerCase()) {
        if (this.providers.length > 0) {
          this.User.providerId = this.providers[0].id;
        }
        this.showProvider = true;
      } else {
        this.User.providerId = "";
        this.showProvider = false;
      }
      const foundRole = this.roles.find(x => x.name.toLowerCase() === role.toLowerCase());
      this.User.role = foundRole ? foundRole.id : 0;

    },
    async addUser() {
      try {
        // this.$emit("update:loading", true)
        if (!this.User.userName || !this.User.roleName) {
          ShowMessage("error", "Please enter the required fields.");
          return;
        }
        if (this.User.providerId === '') {

          this.User.providerId = 0;
        } else {
          this.User.providerId = this.User.providerId ?? 0;
        }
        this.User.role = this.User.role || 0;
        this.User.clientId = Number(this.User.clientId)
        this.loading = true;
        if (this.User.image === "") {
          this.User.image = null;
        }
        // eslint-disable-next-line no-unused-vars
        let response = await userService.add(this.User);
        if (response.status === 200) {
          ShowMessage("success", "User added");
          this.$store.state.addUserDialog = false;
          this.$emit("addSuccess");
        }
        this.loading = false;

      } catch (e) {
        this.loading = false;
        this.loading = false;
        ShowMessage("error", e.response.data.message)
      }
    },
    async updateUser() {
      try {
        // this.$emit("update:loading", true)
        if (this.User.providerId === "") {
          this.User.providerId = 0;
        }
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.update(this.User);
        if (response.status === 200) {
          ShowMessage("success", "User updated");
          this.$store.state.addUserDialog = false;
          this.$emit("addSuccess");

        }
        this.loading = false;

      } catch (e) {
        this.loading = false;
        this.$emit("update:loading", false)
        ShowMessage("error", e.response.data.message)
      }
    },
    async getUser(userId) {
      try {
        // this.$emit("update:loading", true)

        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.getbyId(userId);
        this.User = response.data;

        if (this.User.roleName === "Provider") {
          this.showProvider = true;
          if (this.User.providerId === 0) {
            this.User.providerId = "";
          }
          if (this.User.clientId === 0) {
            this.User.clientId = "";
          }
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$emit("update:loading", false)
        ShowMessage("error", e.response.data.message)
      }
    },

    resetUser() {
      this.User = {
        "id": 0,
        "clientId": sessionStorage.getItem("clientId"),
        "userName": "",
        "email": "",
        "displayName": "",
        "isActive": true,
        "mobile": "",
        "isEmailNotification": true,
        "image": "",
        "permissionList": [
          {
            "roleId": 0,
            "permission": ""
          }
        ],
        "password": ""
      };
      this.IsAddUser = true;
      this.getClients();
    },
    async getClients() {
      try {
        this.loading = true;
        if (this.clientId == null || this.clientId == 0) {
          let response = await ClientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            if (this.User.id < 1) {
              this.User.clientId = this.clients[0].id;
            }

          }

        }
        this.getProviders();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },

    async getRoles() {
      try {
        let response = await roleService.getRolesList(this.User.clientId);
        this.roles = response.data?.items;
        // if (this.roles.length > 1) {
        //   this.User.role = this.roles[0].id
        // }

      } catch (e) {
        console.log(e)
      }
    },
    async getProviders() {
      try {
        this.User.clientId = this.User.clientId ?? 0;
        let response = await providerService.getAll(this.User.clientId);
        this.providers = response.data?.items;
        if (this.User.roleName.toString().trim().toLowerCase() === "Provider".toString().trim().toLowerCase() && this.providers.length > 1) {
          this.User.providerId = this.providers[0].id
        } else {
          this.User.providerId = "";
        }
      } catch (e) {
        console.log(e)
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.providerId = Number(sessionStorage.getItem("providerId"))
    this.userId = Number(sessionStorage.getItem("userId"))
    this.translations = getLanguage();
    this.userEdit = checkPermission("MD_USER_EDIT")
    this.userCreate = checkPermission("MD_USER_CREATE")
    this.getRoles();
    this.getClients();
    if (store.state.pageId > 0) {
      this.edit = true;
      this.getUser(store.state.pageId)
    }

  }
}
</script>

<style scoped>
.add-user-dialog {
  position: static !important;
}

.dialog-loader {
  position: absolute;
  top: 40% !important;
  left: 49% !important;
  z-index: 9999 !important;
}
</style>