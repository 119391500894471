<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row align-items-center py-2">
        <div class="col-12 col-md-8">
          <BreadCrumb :id="property.id" :showBread="true" :view="edit"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="pmsproperties">
            <el-button bg class="btn btn-light" text>
              <i class="bi bi-chevron-left me-2"></i>
              <span>{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-else bg class="btn btn-light" text @click="checkProperty()">
            <i class="bi bi-chevron-left me-2"></i>
            <span>{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
          </el-button>
          <el-button v-if="edit" bg class="border-0 mbtn-primary ms-2" text @click="edit = !edit">
            <span>{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button v-if="property.id > 0 && !edit"
                     :disabled="edit || property.clientId < 1|| !property.reference || property.landlordId < 1"
                     class="border-0 mbtn-primary ms-2"
                     @click="updateProperty">
            <span>{{ getMessageByCode('upd_prop') ?? 'Update Property' }}</span>
          </el-button>
          <el-button v-if="property.id < 1" class="border-0 mbtn-primary ms-2"
                     @click="addProperty">
            <span>{{ getMessageByCode('add_prop') ?? 'Add Property' }}</span>
          </el-button>

        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0">
      <el-tabs v-model="activeTab" @tab-click="handleTabClick" type="border-card">
        <el-tab-pane :label="getMessageByCode('details') ?? 'Details'" :name="0">
          <el-form ref="propertyForm" :model="property" hide-required-asterisk label-position="top">
            <div class="row d-flex align-items-stretch h-100">
              <span class="col-12 me-5 text-caption alert alert-light"><i class="bi bi-info-circle-fill me-2 "></i>Fields with Asterisk(*) are mandatory</span>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('prop') ?? 'Property' }}</span>
                    <span class="bi bi-building-add"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item v-if="clientId < 1">
                      <template #label>
                        <span class="required">{{ getMessageByCode('client') ?? 'Client' }}</span>
                      </template>
                      <el-select v-model="property.clientId" :disabled="edit" class="w-100" filterable
                                 placeholder="Select Client" @change="getLandlords()"
                      >
                        <el-option
                            v-for="item in clients"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="required">{{ getMessageByCode('landlord') ?? 'Landlord' }}</span>
                      </template>
                      <el-select v-model="property.landlordId" :disabled="edit" class="w-100" filterable
                                 placeholder="Select Landlord"
                      >
                        <el-option
                            v-for="item in landords"
                            :key="item.id"
                            :label="item.firstName +' '+item.surname"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="required">{{ getMessageByCode('category') ?? 'Category' }}</span>
                      </template>
                      <el-select v-model="property.categoryId" :disabled="edit" class="w-100" filterable
                                 placeholder="Select Category"
                      >
                        <el-option
                            v-for="item in categories"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="">{{ getMessageByCode('prop_name') ?? 'Property Name' }}</span>
                      </template>
                      <el-input :disabled="edit" v-model="property.propertyName"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="required">{{ getMessageByCode('reference') ?? 'Reference' }}</span>
                      </template>
                      <el-input :disabled="edit" v-model="property.reference"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('propertyType') ?? 'Property Type'">
                      <el-select :disabled="edit" v-model="property.typeOfProperty">
                        <el-option v-for="type in types"
                                   :key="type.optId"
                                   :label="type.optTitle"
                                   :value="type.optId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('address') ?? 'Address' }}</span>
                    <span class="bi bi-sign-turn-right"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item :label="getMessageByCode('address') +' '+ 1 ?? 'Address' + 1">
                      <el-input :disabled="edit" v-model="property.address1"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('address') +' '+ 2 ?? 'Address' + 2">
                      <el-input :disabled="edit" v-model="property.address2"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('address') +' '+ 3 ?? 'Address' + 3">
                      <el-input :disabled="edit" v-model="property.address3"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('postcode')  ?? 'Postcode'">
                      <el-input :disabled="edit" v-model="property.postcode"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('city/town')  ?? 'City/Town'">
                      <el-input :disabled="edit" v-model="property.city"></el-input>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('region')  ?? 'region'">
                      <el-input :disabled="edit" v-model="property.region"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('attributes') ?? 'Attributes' }}</span>
                    <span class="bi bi-sign-turn-right"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item :label="getMessageByCode('price') ?? 'Price'">
                      <template #label>
                        <span>{{ getMessageByCode('price') ?? 'Price' }}</span><span class="text-small text-secondary"> (eg. 999.99)</span>
                      </template>
                      <el-input :disabled="edit" :value="property.price.toFixed(2)" v-model="property.price"
                                class="w-100"
                                type="number">
                        <template #suffix>
                          {{ currency }}
                        </template>
                      </el-input>

                    </el-form-item>
                    <el-form-item :label="getMessageByCode('beds') ?? 'No of Beds'">
                      <el-select :disabled="edit" v-model="property.numberOfBeds">
                        <el-option v-for="bed in beds"
                                   :key="bed.key"
                                   :label="bed.value"
                                   :value="bed.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('bathroom') ?? 'No of Bathrooms'">
                      <el-select :disabled="edit" v-model="property.numberOfBathrooms">
                        <el-option v-for="bed in bathrooms"
                                   :key="bed.key"
                                   :label="bed.value"
                                   :value="bed.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('propertyStatus') ?? 'Property Status'">
                      <el-select :disabled="edit" v-model="property.propertyStatus">
                        <el-option v-for="bed in propertyStatus"
                                   :key="bed.optId"
                                   :label="bed.optTitle"
                                   :value="bed.optId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="getMessageByCode('status') ?? 'Status'">
                      <el-select :disabled="edit" v-model="property.status">
                        <el-option v-for="bed in status2"
                                   :key="bed.optId"
                                   :label="bed.optTitle"
                                   :value="bed.optId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 d-flex align-items-stretch h-100" style="min-height: 200px">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="card  border-0 shadow-sm h-100">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{ getMessageByCode('propImages') ?? 'Property Images' }}</span>
                    <span class="bi bi-image"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <div class="d-flex justify-content-end">
                      <el-button type="primary" @click="uploadImage = true">
                        {{ getMessageByCode('uploadimages') ?? 'Upload Image(s)' }}
                      </el-button>
                    </div>
                    <div class="mt-2">
                      <el-form-item v-if="property.images.length >0">
                        <n-table class="text-small" size="small" :bordered="false" :single-line="false">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>{{ getMessageByCode('file') ?? 'File' }}</th>
                            <th>{{ getMessageByCode('category') ?? 'Category' }}</th>
                            <th>{{ getMessageByCode('image') ?? 'Image' }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(img,index) in property.images" :key="img.fileName">
                            <td style="width: 25px">{{ index + 1 }}</td>
                            <td>
                              <el-tooltip :content="img.fileName">
                                <span>{{ truncateFileName(img.fileName) }}</span>
                              </el-tooltip>
                            </td>
                            <td>{{ getImageCategoryName(img.categoryId) }}</td>
                            <td class="d-flex justify-content-around align-items-center">
                              <n-image
                                  width="30"
                                  height="30"
                                  :src="img.fileData"
                                  class="me-2"
                              />
                              <i class="bi bi-x-circle action-btn" @click="removeImage(img)"></i>
                            </td>
                          </tr>
                          </tbody>
                        </n-table>

                        <!--                        <ul class="list-group  w-100">-->
                        <!--                          <li class="list-group-item  d-flex align-items-center justify-content-between"-->
                        <!--                              v-for="(img,index) in property.images"-->
                        <!--                              :key="index">-->
                        <!--                            <div class="text-caption">-->
                        <!--                              <span class="me-1">{{ index }}.</span>-->
                        <!--                              <el-tooltip :content="img.fileName">-->
                        <!--                                <span>{{ truncateFileName(img.fileName) }}</span>-->
                        <!--                              </el-tooltip>-->
                        <!--                            </div>-->
                        <!--                            <div>-->
                        <!--                              <span>{{ getImageCategoryName(img.categoryId) }}</span>-->
                        <!--                            </div>-->
                        <!--                            <div class="d-flex align-items-center ">-->
                        <!--                              <n-image-->
                        <!--                                  width="30"-->
                        <!--                                  height="30"-->
                        <!--                                  :src="img.fileData"-->
                        <!--                                  class="me-2"-->
                        <!--                              />-->
                        <!--                              <i class="bi bi-x-circle action-btn" @click="removeImage(img)"></i>-->
                        <!--                            </div>-->
                        <!--                          </li>-->
                        <!--                        </ul>-->
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('notes') ?? 'Notes'" :name="1">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary" @click="addNoteDialog = true">{{ getMessageByCode('addNote') ?? 'Add Note' }}
            </el-button>
          </div>
          <el-table :data="property.notes"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column min-width="150" :label="getMessageByCode('note')??'Note'" prop="note" sortable/>

            <el-table-column min-width="250" :label="getMessageByCode('assignedTo')??'Assigned To'"
                             prop="assignedTo"></el-table-column>

            <el-table-column :label="getMessageByCode('reminderDate')??'Reminder Date'" width="140" prop="reminderDate">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ getFormattedDate(scope.row.reminderDate) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('checked')??'Checked'" width="100" prop="checked">
              <template #default="scope">
                <div class="text-end">
                  <el-tag v-if="scope.row.checked" type="success">Yes</el-tag>
                  <el-tag v-else type="success">No</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editNote(scope.row,'edit')"
                  ></i>
                  <i @click="editNote(scope.row,'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('documents') ?? 'Documents'" :name="2">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary" @click="addDocumentDialog = true;resetDocument()">{{
                getMessageByCode('addDocument') ?? 'Add Document'
              }}
            </el-button>
          </div>
          <el-table :data="property.documents"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column min-width="150" :label="getMessageByCode('fileName')??'File Name'" prop="fileName"
                             sortable/>

            <el-table-column width="150" :label="getMessageByCode('fileExtension')??'File Extension'"
                             prop="fileExtension"></el-table-column>

            <el-table-column width="250" :label="getMessageByCode('category')??'Category'"
                             prop="category">
              <template #default="scope">
                <span>{{ getDocumentCategory(scope.row.categoryId) }}</span>
              </template>

            </el-table-column>


            <el-table-column width="150" :label="getMessageByCode('fileSize')??'File Size'"
            >
              <template #default="scope">
                <span>{{ formatFileSize(scope.row.fileSize) }}</span>
              </template>
            </el-table-column>


            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editDocument(scope.row,'edit')"
                  ></i>
                  <i @click="editDocument(scope.row,'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('receipts') ?? 'Receipts'" :name="3">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary" @click="addReceiptDialog = true;resetReceipt()">{{
                getMessageByCode('addReceipt') ?? 'Add Receipt'
              }}
            </el-button>
          </div>
          <el-table :data="receipts"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column width="150" :label="getMessageByCode('tenant')??'Tenant'"
                             prop="tenant"></el-table-column>
            <el-table-column min-width="250" :label="getMessageByCode('property')??'Property'" prop="property"
                             sortable/>


            <el-table-column width="100" :label="currency + ' ' + (getMessageByCode('amount') || 'Amount')"
                             prop="amount">

              <template #default="scope">
                <div class="text-end">
                  <span>{{ scope.row.amount.toFixed(2) }}</span>
                </div>
              </template>

            </el-table-column>


            <el-table-column width="100" :label="getMessageByCode('date')??'Date'"
            >
              <template #default="scope">
                <span>{{ getFormattedDate(scope.row.receiptDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column width="100" :label="getMessageByCode('time')??'Time'"
            >
              <template #default="scope">
                <span>{{ getFormattedTime(scope.row.receiptDate) }}</span>
              </template>
            </el-table-column>


            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <el-tooltip content="Export Pdf">
                    <i
                        class="mgc_pdf_line text-warning action-btn"
                        @click="editReceipt(scope.row,'pdf')"
                    ></i>
                  </el-tooltip>

                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editReceipt(scope.row,'edit')"
                  ></i>
                  <i @click="editReceipt(scope.row,'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="property.id > 0" :label="getMessageByCode('emails') ?? 'Emails'" :name="4">
          <div class="d-flex justify-content-end py-1">
            <el-button type="primary" @click="addEmailDialog = true;resetEmail()">{{
                getMessageByCode('sendEmail') ?? 'Send Email'
              }}
            </el-button>
          </div>
          <el-table :data="emails"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  size="small"
              >
              </el-pagination>
            </template>
            <el-table-column width="200" :label="getMessageByCode('sentDate')??'Sent Date'"
                             prop="sentDate">
              <template #default="scope">
                <span>{{ getEmailFormattedDate(scope.row.sentDate) }}</span>
              </template>
            </el-table-column>
            <el-table-column width="200" :label="getMessageByCode('to')??'To'"
                             prop="to">
              <template #default="scope">
                <span>{{ scope.row.toName }}</span>
                <n-popover trigger="hover">
                  <template #trigger>
                    <i class="bi bi-envelope ms-2 action-btn text-primary"></i>
                  </template>
                  <span>{{ scope.row.to }}</span>
                </n-popover>
              </template>
            </el-table-column>

            <el-table-column :label="getMessageByCode('subject')??'Subject'"
                             prop="subject"></el-table-column>

            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i
                      class="mgc_eye_2_line text-primary action-btn"
                      @click="editEmail(scope.row,'edit')"
                  ></i>
                  <i @click="editEmail(scope.row,'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>


    <!--    Dialogs-->
    <el-dialog v-model="uploadImage" :title="getMessageByCode('uploadImage') ?? 'Upload Image/s'"
               style="width: auto; max-width: 500px">
      <el-form label-position="top" require-asterisk-position="right">
        <el-form-item :label="getMessageByCode('category') ?? 'Category'">
          <el-select v-model="image.categoryId">
            <el-option v-for="type in imageCategories"
                       :key="type.optId"
                       :label="type.optTitle"
                       :value="type.optId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('description') ?? 'Description'">
          <el-input type="textarea" :rows="3" v-model="image.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="image.fileName">
            <template #prepend>
              <el-upload id="upload-photo" multiple accept="image/*" :key="fileInputKey" :auto-upload="false"
                         @input="onFileInput" :show-file-list="false"
                         class="mb-0">
                <template #trigger>
                  <el-button type="primary">{{ getMessageByCode('selectimages') ?? 'Select Image(s)' }}</el-button>
                </template>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-collapse-transition>
          <el-form-item v-if="property.images.length >0">
            <ul class="list-group  w-100">
              <li class="list-group-item py-0 d-flex justify-content-between" v-for="(img,index) in property.images"
                  :key="index">
                <div class="text-caption">
                  <span class="me-1">{{ index }}.</span>
                  <el-tooltip :content="img.fileName">
                    <span>{{ truncateFileName(img.fileName) }}</span>
                  </el-tooltip>
                </div>
                <i class="bi bi-x-circle action-btn" @click="removeImage(img)"></i>
              </li>
            </ul>
          </el-form-item>
        </el-collapse-transition>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg class="ms-2" @click="clearImages('cancel'); uploadImage = false">Cancel</el-button>
          <el-button type="primary" class="ms-2" @click="uploadImage = false;clearImages('ok')">Ok</el-button>
        </div>
      </template>
      <n-spin class="fileUpload" stroke="#2a5699" size="small" v-if="uploadingFiles"></n-spin>
    </el-dialog>
    <el-dialog v-model="addNoteDialog"
               :title="note.id > 0 ?  getMessageByCode('updateNote') ?? 'Update Note' :  getMessageByCode('addNote') ?? 'Add Note'"
               style="width: auto; max-width: 500px">
      <el-form label-position="top" require-asterisk-position="right">
        <!--        <el-form-item :label="getMessageByCode('assignedTo') ?? 'Assigned To'">
                  <el-select v-model="note.assignedTo">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>-->
        <el-form-item :label="getMessageByCode('note') ?? 'Note'">
          <el-input type="textarea" :rows="5" v-model="note.note"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="d-flex justify-content-between p-0">
              <span>{{ getMessageByCode('reminderDate') ?? 'Reminder Date' }}</span>
              <span>{{ getMessageByCode('checked') ?? 'Checked?' }}</span>
            </div>
          </template>
          <div class="d-flex justify-content-between align-items-center w-100">
            <el-date-picker v-model="note.reminderDate" :format="dateFormat" :editable="false"
                            class="w-75"></el-date-picker>
            <div class="d-flex justify-content-end">
              <el-switch v-model="note.checked"></el-switch>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg class="ms-2" @click="addNoteDialog = false">Cancel</el-button>
          <el-button type="primary" class="ms-2" @click="addNoteDialog = false;clearImages('ok')">
            {{
              note.id > 0 ? getMessageByCode('updateNote') ?? 'Update Note' : getMessageByCode('addNote') ?? 'Add Note'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addDocumentDialog"
               :title="document.id > 0 ?  getMessageByCode('updateDocument') ?? 'Update Document' :  getMessageByCode('addDocument') ?? 'Add Document'"
               style="width: auto; max-width: 500px">
      <el-form label-position="top" require-asterisk-position="right">
        <el-form-item :label="getMessageByCode('category') ?? 'Category'">
          <el-select v-model="document.categoryId">
            <el-option v-for="item in documentCategories"
                       :key="item.optId"
                       :label="item.optTitle"
                       :value="item.optId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('document') ?? 'Document'">
          <el-input v-model="document.fileName">
            <template #prepend>
              <el-upload :auto-upload="false" :on-change="handleFileChange" :show-file-list="false"
                         class="mb-0">
                <template #trigger>
                  <el-button type="primary">{{ getMessageByCode('select_file') ?? 'select file' }}</el-button>
                </template>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg class="ms-2" @click="addDocumentDialog = false">Cancel</el-button>
          <el-button v-if="document.id < 1" type="primary" class="ms-2"
                     @click="addDocumentDialog = false;addPropertyDocument()">
            {{
              getMessageByCode('addDocument') ?? 'Add Document'
            }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addDocumentDialog = false;updatePropertyDocument()">
            {{
              getMessageByCode('updateDocument') ?? 'Update Document'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addReceiptDialog"
               :title="receipt.id > 0 ?  getMessageByCode('updateReceipt') ?? 'Update Receipt' :  getMessageByCode('addReceipt') ?? 'Add Receipt'"
               style="width: auto; max-width: 500px">
      <el-form label-position="top" require-asterisk-position="right">
        <el-form-item :label="getMessageByCode('property') ?? 'Property'">
          <el-input v-model="property.propertyName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="getMessageByCode('tenant') ?? 'Tenant'">
          <el-select v-model="receipt.tenantId">
            <el-option v-for="item in pmsTenants"
                       :key="item.id"
                       :label="getTenantName(item)"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('amount') ?? 'Amount'">
          <el-input type="number" v-model="receipt.amount">

          </el-input>
        </el-form-item>
        <el-form-item :label="getMessageByCode('receiptDate') ?? 'Receipt Date'">
          <el-date-picker class="w-100" :format="dateFormat" :editable="false" v-model="receipt.receiptDate">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="getMessageByCode('notes') ?? 'Notes'">
          <el-input type="textarea" :rows="3" v-model="receipt.notes"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg class="ms-2" @click="addReceiptDialog = false">Cancel</el-button>
          <el-button v-if="receipt.id < 1" type="primary" class="ms-2"
                     @click="addReceiptDialog = false;addReceipt()">
            {{
              getMessageByCode('addReceipt') ?? 'Add Receipt'
            }}
          </el-button>
          <el-button v-else type="primary" class="ms-2" @click="addReceiptDialog = false;updateReceipt()">
            {{
              getMessageByCode('updateReceipt') ?? 'Update Receipt'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addEmailDialog" align-center
               :title="email.id > 0 ?  getMessageByCode('viewEmail') ?? 'View Email' :  getMessageByCode('sendEmail') ?? 'Send Email'"
               style="width: auto; max-width: 670px">
      <el-form label-position="top" require-asterisk-position="right" class="row">
        <!--        <el-form-item class="col-12 col-md-6" :label="getMessageByCode('property') ?? 'Property'">
                  <el-input v-model="property.propertyName" disabled></el-input>
                </el-form-item>
                <el-form-item class="col-12 col-md-6" :label="getMessageByCode('emailFrom') ?? 'From'">
                  <el-input v-model="fromEmail" disabled></el-input>
                </el-form-item>-->
        <el-form-item required class="col-12 col-md-6" :label="getMessageByCode('to') ?? 'To'">
          <el-select v-model="email.emailTo" :disabled="email.id > 0">
            <el-option v-for="item in pmsTenants"
                       :key="item.id"
                       :label="getTenantName(item)"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="col-12 col-md-6" :label="getMessageByCode('cc') ?? 'cc'">
          <el-input :disabled="email.id > 0" v-model="email.copyTo"></el-input>
        </el-form-item>
        <el-form-item required :label="getMessageByCode('subject') ?? 'Subject'">
          <el-input :disabled="email.id > 0" v-model="email.subject" :maxlength="250" show-word-limit></el-input>
        </el-form-item>
        <el-form-item required :disabled="email.id > 0" :label="getMessageByCode('emailBody') ?? 'Email Body'">
          <ckeditor :editor="editor" class="w-100" v-model="email.body" :config="editorConfig" :disabled="email.id > 0"
                    style="width: 100% !important; min-height: 200px !important;"></ckeditor>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <div v-if="email.id < 1">
            <el-button text bg class="ms-2" @click="addEmailDialog = false">Cancel</el-button>
            <el-button type="primary" class="ms-2"
                       :disabled="email.emailTo < 1 || !email.subject || !email.body"
                       @click="addEmailDialog = false;addEmail()">
              {{
                getMessageByCode('sendEmail') ?? 'Send Email'
              }}
            </el-button>
          </div>
          <el-button v-else type="primary" class="ms-2"
                     @click="addEmailDialog = false">
            {{
              getMessageByCode('close') ?? 'Close'
            }}
          </el-button>
        </div>
      </template>
    </el-dialog>


    <el-dialog v-model="deleteNoteDialog"
               :title="getMessageByCode('deleteNote') ?? 'Delete Note'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold text-danger"> {{
               note.note
             }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteNoteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteNoteDialog = false; deletePropertyNote()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteEmailDialog"
               :title="getMessageByCode('deleteEmail') ?? 'Delete Email'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold ">selected email?</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteEmailDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteEmailDialog = false; deleteEmail()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDocumentDialog"
               :title="getMessageByCode('deleteDocument') ?? 'Delete Document'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} <span class="mfw-bold text-danger"> {{
               document.fileName
             }}</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDocumentDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteDocumentDialog = false; deletePropertyDocument()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteReceiptDialog"
               :title="getMessageByCode('deleteReceipt') ?? 'Delete Receipt'"
               style="width: auto; max-width: 500px">
      <template #default>
         <span>{{
             getMessageByCode('r_u_sure') ?? 'Are you sure ? You want to '
           }} {{ getMessageByCode('delete') ?? 'Delete' }} selected receipt?</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteReceiptDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button type="danger" class="ms-2" @click="deleteReceiptDialog = false; deleteReceipt()">
            {{ getMessageByCode('delete') ?? 'Delete' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>


<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {getLanguage} from "@/Utility/getLanguage";
import propertyService from "@/Services/ComponentsServices/pmsPropertiesService";
import {ShowMessage} from "@/Utility/Utility";
import store from "@/store";
import ClientService from "@/Services/ComponentsServices/clientService";
import contactService from "@/Services/ComponentsServices/contactService";
import optionService from "@/Services/ComponentsServices/optionService";
import {NImage, NSpin} from "naive-ui";
import moment from "moment/moment";
import configService from "@/Services/ComponentsServices/configService";
import pmsPropertiesService from "@/Services/ComponentsServices/pmsPropertiesService";
import pmsTenantService from "@/Services/ComponentsServices/pmsTenantService";
import receiptService from "@/Services/ComponentsServices/receiptService";
import pmsEmailService from "@/Services/ComponentsServices/pmsEmailService";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {NPopover} from "naive-ui";
import {NTable} from "naive-ui";

// import SizeCalc from "@/components/Services/SizeCalc";

export default {
  name: "AddPmsProperties",
  components: {NTable, NImage, BreadCrumb, NSpin, ckeditor: CKEditor.component, NPopover},
  data() {
    return {
      loading: false,
      edit: false,
      uploadImage: false,
      uploadingFiles: false,
      addNoteDialog: false,
      addReceiptDialog: false,
      addDocumentDialog: false,
      addEmailDialog: false,
      deleteEmailDialog: false,
      deleteDocumentDialog: false,
      deleteReceiptDialog: false,
      deleteNoteDialog: false,
      editor: ClassicEditor,
      currency: "£",
      currencyPosition: "left",
      editorConfig: {
        height: 500
      },
      activeTab: 0,
      translations: [],
      fileInputKey: new Date(),
      clientId: sessionStorage.getItem("clientId"),
      clients: [],
      landords: [],
      pmsTenants: [],
      contacts: [],
      documentCategories: [],
      categories: [
        {key: 1, value: "To Let"},
        {key: 2, value: "For Sale"}
      ],
      beds: [
        {key: 1, value: 1},
        {key: 2, value: 2},
        {key: 3, value: 3},
        {key: 4, value: 4},
        {key: 4, value: 5},
      ],
      bathrooms: [
        {key: 1, value: 1},
        {key: 2, value: 2},
        {key: 3, value: 3},
      ],
      propertyStatus: [],
      status2: [],
      types: [],
      imageCategories: [],
      image: {
        propertyId: 0,
        fileData: "",
        fileName: "",
        fileSize: "",
        fileExtension: "",
        mimeType: "",
        sortOrder: 0,
        categoryId: "",
        description: "",
      },
      property: {
        "id": 0,
        "propertyName": "",
        "landlordId": "",
        "clientId": 0,
        "categoryId": 1,
        "reference": "",
        "price": 0,
        "typeOfProperty": 0,
        "numberOfBeds": 1,
        "numberOfBathrooms": 1,
        "propertyStatus": 0,
        "status": 0,
        "address1": "",
        "address2": "",
        "address3": "",
        "postcode": "",
        "displayOrder": 0,
        "description": "",
        "pets": true,
        "smoke": true,
        "active": true,
        "city": "",
        "region": "",
        images: [],
        notes: [],
        documents: []
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        tenantId: 0,
        propertyId: store.state.pageId,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
      dateFormat: "DD/MM/YYYY",
      fromEmail: "",
      note: {
        "id": 0,
        "propertyId": 0,
        "note": "",
        "addedBy": "",
        "addedDate": new Date(),
        "assignedTo": 0,
        "reminderDate": new Date(),
        "checked": false
      },
      document: {
        "id": 0,
        "propertyId": 0,
        "categoryId": 0,
        "fileName": "",
        "fileExtension": "",
        "fileSize": "",
        "fileData": "",
        "addedDate": new Date(),
        "addedBy": "",
        "mimeType": "",
      },
      receipts: [],
      receipt: {
        "id": 0,
        "propertyId": 0,
        "templateId": 0,
        "tenantId": 0,
        "amount": 0,
        "receiptDate": new Date(),
        "notes": ""
      },
      emails: [],
      email: {
        "id": 0,
        "emailFrom": 0,
        "emailTo": 0,
        "copyTo": "",
        "subject": "",
        "body": "",
        "propertyId": 0,
        "clientId": 0,
        "sentDate": new Date().toISOString()
      }
    }
  },
  computed: {
    pmsTenantService() {
      return pmsTenantService
    },

    truncateFileName() {
      return function (fileName) {
        return fileName.length > 20 ? fileName.substring(0, 20) + '...' : fileName;
      }
    }
  },
  methods: {
    getTenantName(item) {
      return item.firstName + ' ' + item.surname;
    },
    getDocumentCategory(id) {
      return this.documentCategories.find(x => x.optId === id).optTitle ?? "";
    },
    editDocument(document, type) {
      this.document = JSON.parse(JSON.stringify(document));
      switch (type) {
        case 'edit':
          this.addDocumentDialog = true;
          break;
        case 'delete':
          this.deleteDocumentDialog = true;
          break
      }
    },
    editReceipt(receipt, type) {
      this.receipt = JSON.parse(JSON.stringify(receipt));
      switch (type) {
        case 'edit':
          this.addReceiptDialog = true;
          break;
        case 'delete':
          this.deleteReceiptDialog = true;
          break
      }
    },
    editEmail(email, type) {
      this.email = JSON.parse(JSON.stringify(email));
      switch (type) {
        case 'edit':
          this.addEmailDialog = true;
          break;
        case 'delete':
          this.deleteEmailDialog = true;
          break
      }
    },
    formatFileSize(bytes) {
      bytes = Number(bytes);
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Bytes';
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const size = bytes / Math.pow(1024, i);
      return size.toFixed(2) + ' ' + sizes[i];
    },
    getFormattedDate(date) {
      return moment(date).format(this.dateFormat)
    },
    getFormattedTime(date) {
      return moment(date).format("LT")
    },
    getEmailFormattedDate(date) {
      return moment(date).format(this.dateFormat + " hh:mm A")
    },
    editNote(note, type) {
      this.note = JSON.parse(JSON.stringify(note));
      switch (type) {
        case 'edit':
          this.addNoteDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;

    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
    },
    getImageCategoryName(id) {
      if (this.imageCategories.length < 1) {
        return "";
      }
      return this.imageCategories.find(x => x.optId === id).optTitle ?? "";
    },
    getBase64Image(imgData) {
      if (imgData === '' || imgData === null || imgData === undefined) {
        return "";
      }
      if (!imgData.startsWith("data:image/png;base64,")) {
        return "data:image/png;base64," + imgData;
      }
      return imgData;
    },
    removeImage(img) {
      let index = this.property.images.findIndex(function (image) {
        return image === img;
      });
      if (index > -1) {
        this.property.images.splice(index, 1);
      }
    },
    async addProperty() {
      this.loading = true;
      try {
        let validatedMsg = this.validateProperty(this.property);
        if (validatedMsg) {
          ShowMessage("error", validatedMsg);
          this.loading = false;
          return;
        }
        let response = await propertyService.addProperty(this.property);

        if (Number(response.status) === 200) {
          ShowMessage("success", "Property added successfully");
          this.$router.push('pmsproperties')
        }
        this.loading = false;
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response.data.message);
        this.loading = false;
      }
    },
    validateProperty(property) {
      if (property.landlordId < 1) {
        return "Please select the landlord";
      }
      if (!property.propertyName) {
        return "Please enter the property name";
      }
      if (!property.reference) {
        return "Please enter the property reference";
      }
      return "";
    },
    async updateProperty() {
      this.loading = true;
      this.$refs.propertyForm.validate(async (valid) => {
        if (valid) {
          try {

            // eslint-disable-next-line no-unused-vars
            let response = await propertyService.updateProperty(this.property);
            console.log(response);
            ShowMessage("success", "Property updated successfully");
            this.loading = false;
          } catch (e) {
            console.log(e)
            ShowMessage("error", "Property updating failed");
            this.loading = false;
          }
        } else {
          this.loading = false;
          ShowMessage("error", "Please enter the required fields!")
        }
      });
    },
    async getProperty() {
      try {
        this.loading = true;
        let response = await propertyService.getProperty(store.state.pageId);
        this.property = response.data ?? {};
        this.filter.propertyId = this.property.id ?? 0;
        this.edit = !this.edit;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
      this.loading = false;
    },
    async getLandlords() {
      try {
        let response = await contactService.getAllContacts(this.property.clientId, 3);
        this.landords = response?.data?.items ?? []
      }
          // eslint-disable-next-line no-empty
      catch (e) {
      }
    },
    async getListOptions() {
      try {
        this.loader = true;
        let response = await optionService.getOptionsByHeaderId(0, 36)
        this.propertyStatus = response.data ?? {};
        if (this.propertyStatus.length > 0) {
          if (this.property.propertyStatus < 1) {
            this.property.propertyStatus = this.propertyStatus[0].optId;
          }
        }
        let response2 = await optionService.getOptionsByHeaderId(0, 40)
        this.status2 = response2.data ?? {};
        if (this.status2.length > 0) {
          if (this.property.status < 1) {
            this.property.status = this.status2[0].optId;
          }
        }
        let response3 = await optionService.getOptionsByHeaderId(0, 44)
        this.types = response3.data ?? {};
        if (this.types.length > 0) {
          if (this.property.typeOfProperty < 1) {
            this.property.typeOfProperty = this.types[0].optId;
          }
        }
        let response4 = await optionService.getOptionsByHeaderId(0, 42)
        this.imageCategories = response4.data ?? {};
        if (this.imageCategories.length > 0) {
          if (this.image.categoryId < 1) {
            this.image.categoryId = this.imageCategories[0].optId;
          }
        }
        this.loader = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loader = false;
      }
    },
    async getClients() {
      try {
        this.loading = true;
        if (this.clientId == null || Number(this.clientId) < 1) {
          let response = await ClientService.getAll();
          this.clients = response.data.items ?? [];
          if (this.clients.length > 0) {
            if (this.property.id < 1) {
              this.property.clientId = this.clients[0].id;
              this.filter.clientId = this.clients[0].id;
            }
          }
        } else {
          this.property.clientId = Number(this.clientId);
        }
        await this.getLandlords();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    checkProperty() {
      if (this.property.id < 1) {
        this.$router.push('pmsproperties');
        return;
      }
      this.edit = !this.edit
    },
    getMessageByCode(code) {
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    onFileInput(event) {
      this.uploadingFiles = true;
      const fileList = event.target.files;
      let promises = [];

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        const image = {...this.image}; // Ensure a new image object for each file
        image.fileName = file.name;
        image.fileExtension = file.name.split('.').pop();
        image.fileSize = file.size.toString();
        image.mimeType = file.type;

        const promise = this.convertFileToBase64(file)
            .then((base64Data) => {
              image.fileData = `data:${file.type};base64,${base64Data}`;
              return image; // Return the image object after conversion
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            });

        promises.push(promise);
      }

      Promise.all(promises).then((imagesList) => {
        // Concatenate the new images with the existing ones
        this.property.images = [...this.property.images, ...imagesList];
        this.uploadingFiles = false;
      });
    },
    // eslint-disable-next-line no-unused-vars
    onFileChanged(file, fileList) {
      this.fileInputKey = new Date();
      this.fileInputKey = new Date();
      const fileInput = document.getElementById("upload-photo");
      if (fileInput.files && fileInput.files.length) {
        const selectedFile = fileInput.files[0];

        // Extract file properties
        this.image.fileName = selectedFile.name;
        this.image.fileSize = selectedFile.size.toString();
        this.image.fileExt = selectedFile.name.split('.').pop();
        this.image.mimeType = selectedFile.type;
        this.image.lastModifiedAt = new Date(selectedFile.lastModified);
        this.convertFileToBase64(selectedFile)
            .then((base64Data) => {
              this.image.fileData = `data:${selectedFile.type};base64,${base64Data}`;
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            });
      }
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result.split(',')[1]); // Get the Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    handleFileChange(file) {
      const selectedFile = file.raw;
      this.document.fileName = file.name
      this.document.fileSize = file.size.toString();
      this.document.fileExtension = file.raw.name.split('.').pop();
      this.document.mimeType = selectedFile.type;
      this.returnDocObject(selectedFile, (byteArray) => {
        this.document.fileData = byteArray;
      });
    },
    returnDocObject(file, callback) {
      const reader = new FileReader();
      reader.onload = () => {
        const byteArray = new Uint8Array(reader.result);
        const base64String = btoa(String.fromCharCode(...byteArray));
        callback(base64String);  // Return the base64-encoded string
      };
      reader.readAsArrayBuffer(file);  // Read file as ArrayBuffer
    },
    async getConfigs() {
      try {
        this.loading = true;
        let id = 0;
        if (this.clientId === null) {
          id = 0;
        } else {
          id = this.clientId;
        }

        let response = await configService.getByKey(id, "DateFormat");
        this.dateFormat = response.data.value ?? "DD/MM/YYYY";
        let response2 = await configService.getByKey(id, "FromEmail");
        this.fromEmail = response2.data.value ?? "admin@fdesigns.co.uk";
        let response3 = await configService.getByKey(id, "CurrencySymbol");
        this.currency = response3.data.value ?? "£";
        let response4 = await configService.getByKey(id, "CurrencyPosition");
        this.currencyPosition = response4.data.value ?? "left";
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getDocumentCategories() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByHeaderId(0, 47)
        this.documentCategories = response.data ?? {};
        if (this.documentCategories.length > 0) {
          if (this.document.categoryId < 1) {
            this.document.categoryId = this.documentCategories[0].optId;
          }
        }

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    handleTabClick(tabName) {
      let tab = Number(tabName.index);
      switch (tab) {
        case 0:
          this.getProperty();
          break;
        case 1:
          this.getPropertyNotes();
          break;
        case 2:
          this.getPropertyDocuments();
          break;
        case 3:
          this.getReceipts();
          this.getTenants();
          break;
        case 4:
          this.getTenants();
          this.getEmails();
          break;
      }
    },
    resetDocument() {
      this.document = {
        "id": 0,
        "propertyId": 0,
        "categoryId": this.documentCategories[0].optId,
        "fileName": "",
        "fileExtension": "",
        "fileSize": "",
        "fileData": "",
        "addedDate": new Date(),
        "addedBy": "",
        "mimeType": "",
      }
    },
    resetNote() {
      this.note = {
        "id": 0,
        "propertyId": 0,
        "note": "",
        "addedBy": "",
        "addedDate": new Date(),
        "assignedTo": 0,
        "reminderDate": new Date(),
        "checked": false
      }
    },
    resetReceipt() {
      this.receipt = {
        "id": 0,
        "propertyId": store.state.pageId,
        "templateId": 0,
        "tenantId": this.pmsTenants[0]?.id,
        "amount": 0,
        "receiptDate": new Date().toISOString(),
        "notes": ""
      }
    },
    resetEmail() {
      this.email = {
        "id": 0,
        "emailFrom": "",
        "emailTo": this.contacts[0]?.id ?? "",
        "copyTo": "",
        "subject": "",
        "body": "",
        "propertyId": 0,
        "clientId": 0,
        "sentDate": new Date().toISOString(),
      }
    },
    clearImages(type) {
      this.image = {
        propertyId: 0,
        fileData: "",
        fileName: "",
        fileSize: "",
        fileExtension: "",
        mimeType: "",
        sortOrder: 0,
        categoryId: "",
        description: "",
      };
      if (type === 'cancel') {
        this.property.images = [];
      }
    },
    //PMS Property Documents Methods
    async getPropertyDocuments() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.getDocuments(this.filter);
        this.property.documents = response?.data?.items ?? [];

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async addPropertyDocument() {
      try {
        this.document.propertyId = this.property.id;
        console.log(this.document)
        this.loading = true;
        let response = await pmsPropertiesService.addDocument(this.document);
        if (response?.data?.id > 0) {
          ShowMessage("success", "Document Addedd");
          await this.getPropertyDocuments();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async updatePropertyDocument() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.updateDocument(this.document);
        console.log(response);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async deletePropertyDocument() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.deleteDocument(this.document.id);
        if (response?.data) {
          ShowMessage("success", "Document Deleted");
          await this.getPropertyDocuments();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    //PMS Property Notes Methods
    async getPropertyNotes() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.getNotes(this.filter);
        this.property.notes = response?.data?.items ?? [];

      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async addPropertyNote() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.addNote(this.note);
        if (response?.data) {
          ShowMessage("success", "Note Added");
          await this.getPropertyNotes();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async updatePropertyNote() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.updateNote(this.note);
        if (response?.data) {
          ShowMessage("success", "Note Updated");
          await this.getPropertyNotes();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async deletePropertyNote() {
      try {
        this.loading = true;
        let response = await pmsPropertiesService.deleteNote(this.note.id);
        if (response?.data) {
          ShowMessage("success", "Note Deleted");
          await this.getPropertyNotes();
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    // Receipts

    async getReceipts() {
      this.loading = true;
      try {
        let response = await receiptService.getReceipts(this.filter);
        this.receipts = response?.data?.items ?? [];
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async addReceipt() {
      this.loading = true;
      try {
        console.log(this.receipt)
        let response = await receiptService.addReceipt(this.receipt);
        if (response.status === 200) {
          ShowMessage("success", "Receipt added");
          await this.getReceipts();
        }
      } catch (e) {
        ShowMessage("error", "Receipt adding failed, try again later");
      }
      this.loading = false;
    },
    async updateReceipt() {
      this.loading = true;
      try {
        let response = await receiptService.updateReceipt(this.receipt);
        if (response.status === 200) {
          ShowMessage("success", "Receipt updated");
          await this.getReceipts();
        }
      } catch (e) {
        ShowMessage("error", "Receipt updating failed, try again later");
      }
      this.loading = false;
    },
    async deleteReceipt() {
      this.loading = true;
      try {
        let response = await receiptService.deleteReceipt(this.receipt.id);
        if (response.status === 200) {
          ShowMessage("success", "Receipt deleted");
          await this.getReceipts();
        }
      } catch (e) {
        ShowMessage("error", "Receipt deleting failed, try again later");
      }
      this.loading = false;
    },

// Emails

    async getEmails() {
      this.loading = true;
      try {

        let response = await pmsEmailService.getEmails(this.filter);
        this.emails = response?.data?.items ?? [];
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      this.loading = false;
    },
    async addEmail() {
      this.loading = true;
      try {
        if (this.email.emailTo < 1) {
          ShowMessage("warning", "Please select a recipient first");
          return;
        }
        if (!this.email.subject || !this.email.body) {
          ShowMessage("warning", "Subject and body are mandatory");
          return;
        }

        this.email.clientId = this.property.clientId;
        this.email.emailFrom = this.fromEmail;
        this.email.propertyId = this.property.id;
        let response = await pmsEmailService.addEmail(this.email);
        if (response.status === 200) {
          ShowMessage("success", "Email added");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email adding failed, try again later");
      }
      this.loading = false;
    },
    async updateEmail() {
      this.loading = true;
      try {
        let response = await pmsEmailService.updateEmail(this.email);
        if (response.status === 200) {
          ShowMessage("success", "Email updated");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email updating failed, try again later");
      }
      this.loading = false;
    },
    async deleteEmail() {
      this.loading = true;
      try {
        let response = await pmsEmailService.deleteEmail(this.email.id);
        if (response.status === 200) {
          ShowMessage("success", "Email deleted");
          await this.getEmails();
        }
      } catch (e) {
        ShowMessage("error", "Email deleting failed, try again later");
      }
      this.loading = false;
    },


    async getTenants() {
      try {
        console.log(this.filter.clientId)
        let response = await contactService.getTenantsAndLandlords(this.property.clientId);
        this.pmsTenants = response?.data?.items ?? [];
        if (this.pmsTenants.length > 0) {
          this.receipt.tenantId = this.pmsTenants[0].id;
          this.filter.tenantId = this.pmsTenants[0].id;
        } else {
          this.receipt.tenantId = "";
        }

        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    },

  },
  created() {
    if (store.state.pageId > 0) {
      this.getProperty();
    }
    this.translations = getLanguage();
    this.getClients();
    this.getListOptions();
    this.getConfigs();
    this.getDocumentCategories();
  }
}
</script>
<style scoped>
.fileUpload {
  position: absolute !important;
  top: 50% !important;
  left: 50%;
}

.ck-editor__editable_inline {
  min-height: 500px; /* Set the minimum height to 500px */
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 200px !important;
}
</style>