import {createRouter, createWebHistory} from "vue-router";
import Login from "@/components/Authentication/Login.vue";
import SADashboard from "@/components/Dashboard/SADashboard";
import HousingDashboard from "@/components/Dashboard/HousingDashboard.vue";
import PmsDashboard from "@/components/Dashboard/PmsDashboard.vue";
import Clients from "@/components/Clients/Clients";
import clientContacts from "@/components/ClientContacts/ClientContacts.vue";
import clientContact from "@/components/ClientContacts/ClientContact.vue";
import AddClient from "@/components/Clients/AddClient";
import EditClient from "@/components/Clients/EditClient";
import Providers from "@/components/Providers/ProvidersList.vue";
import AddProvider from "@/components/Providers/AddProvider.vue";
import Compliance from "@/components/Compliance/ComplianceList.vue";
import Properties from "@/components/Properties/PropertiesList.vue";
import AddProperty from "@/components/Properties/AddProperty.vue";
import Users from "@/components/Users/UsersList.vue";
import Tenant from "@/components/Tenant/TenantList.vue"
import AddTenant from "@/components/Tenant/AddTenant.vue"
import PmsTenantList from "@/components/PmsTenants/PmsTenantList.vue"
import AddPmsTenant from "@/components/PmsTenants/AddPmsTenant.vue"
import Settings from "@/components/Settings/Settings";
import Documents from "@/components/Documents/Documents.vue"
import UserProfile from "@/components/User/UserProfile"
import EmptyRooms from "@/components/Properties/Rooms/EmptyRooms"
import {isAuthenticated, isAdmin} from "@/components/Authentication/Authenticate";
import ForBidden from "@/components/404/Forbidden.vue";
import PageNotFound from "@/components/404/PageNotFound";
import {ShowMessage} from "@/Utility/Utility";
import OptionsList from "@/components/Options/OptionsList.vue";
import RolesList from "@/components/Roles/RolesList.vue"
import RolesPermissions from "@/components/Roles/RolesPermissions.vue";
import ForgotPassword from '@/components/Authentication/ForgotPassword.vue'
import ResetPassword from "@/components/Authentication/ResetPassword.vue"
import TemplatesList from "@/components/Templates/TemplatesList.vue"
import DocumentsExpiry from "@/components/Reports/DocumentsExpiry.vue";
import MissingDocuments from "@/components/Reports/MissingDocuments.vue";
import TenantsReport from "@/components/Reports/TenantsReport.vue";
import RoomReport from "@/components/Reports/EmptyRooms.vue";
import MarketList from "@/components/Market/MarketList.vue";
import PackagesList from "@/components/Market/PackagesList.vue"
import Signup from "@/components/Authentication/Signup.vue";
import Register from "@/components/Authentication/Register.vue";
import AddUser from "@/components/Users/AddUser.vue"
import Customers from "@/components/Customers/Customers.vue"
import Customer from "@/components/Customers/Customer.vue"
import Contacts from "@/components/Contacts/Contacts.vue"
import {checkPermission} from "@/Utility/CheckPermission";
import {getMessageByCode} from "@/Utility/getMessagebyCode";
import TenancyAgreements from "@/components/Agreement/TenancyAgreements.vue";
import PmsProperties from "@/components/PmsProperty/PmsProperties.vue";
import PmsRent from "@/components/Rent/RentList.vue"
import AddPmsProperty from "@/components/PmsProperty/AddPmsProperty.vue";
import MoneyTrails from "@/components/MoneyTrail/MoneyTrails.vue"
import AddMoneyTrail from "@/components/MoneyTrail/AddMoneyTrail.vue";
import PmsEmails from "@/components/PmsEmails/PmsEmails.vue";
import StaticPages from "@/components/StaticPages/StaticPages.vue";
import StaticPage from "@/components/StaticPages/StaticPage.vue";
import Faqs from "@/components/Faqs/FaqsList.vue"
import LoginQuotes from "@/components/Core/LoginQuotes.vue"
import LoginImagesList from "@/components/Core/LoginImages.vue";
const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    }, {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    }, {
        path: "/password-reset",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/403",
        name: "ForBidden",
        component: ForBidden,
    },

     {
        path: "/loginimages",
        name: "LoginImagesList",
        component: LoginImagesList,
        beforeEnter: (to, from, next) => {

            to.meta.pageTitle = getMessageByCode('loginImages') ?? 'Login Images'
            to.meta.breadcrumbs = getMessageByCode('loginImages') ?? 'Login Images'
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },{
        path: "/loginquotes",
        name: "LoginImages",
        component: LoginQuotes,
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('loginQuotes') ?? 'Login Quotes'
            to.meta.breadcrumbs = getMessageByCode('loginQuotes') ?? 'Login Quotes'
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },

    {
        path: "/dashboard",
        name: "SADashboard",
        component: SADashboard,
        meta: {
            pageTitle: 'Dashboard',
            breadcrumbs: ['Dashboard'],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('dashboard') ?? 'Dashboard'
            to.meta.breadcrumbs = getMessageByCode('dashboard') ?? 'Dashboard'
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/customers",
        name: "Customers",
        component: Customers,
        meta: {
            pageTitle: 'Customers',
            breadcrumbs: ['Customers'],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('customers') ?? 'Customers'
            to.meta.breadcrumbs = getMessageByCode('customers') ?? 'Customers'
            if (isAuthenticated()) {
                next();
            }
            /*if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }*/
        },
    }, {
        path: "/customer",
        name: "Customer",
        component: Customer,
        meta: {
            pageTitle: 'Customer',
            breadcrumbs: ["Customer"],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('customer') ?? "Customer"
            to.meta.breadcrumbs = getMessageByCode('customer') ?? ["Customer"]
            if (isAuthenticated()) {
                next();
            }
            /*if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }*/
        },
    },
    {
        path: "/moneytrails",
        name: "MoneyTrails",
        component: MoneyTrails,
        meta: {
            pageTitle: 'MoneyTrails',
            breadcrumbs: ['MoneyTrails'],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('moneyTrails') ?? 'MoneyTrails'
            to.meta.breadcrumbs = getMessageByCode('moneyTrails') ?? 'MoneyTrails'
            if (isAuthenticated()) {
                next();
            }
            /*if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }*/
        },
    }, {
        path: "/moneytrail",
        name: "AddMoneyTrail",
        component: AddMoneyTrail,
        meta: {
            pageTitle: 'MoneyTrail',
            breadcrumbs: ["MoneyTrail", "Add"],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('moneyTrail') ?? "MoneyTrail"
            to.meta.breadcrumbs = getMessageByCode('moneyTrail') ?? ["MoneyTrail", "Add"]
            if (isAuthenticated()) {
                next();
            }
            /*if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }*/
        },
    },
    {
        path: "/housing",
        name: "HousingDashboard",
        component: HousingDashboard,
        meta: {
            pageTitle: "Dashboard",
            breadcrumbs: ["Dashboard"],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('dashboard') ?? 'Dashboard'
            to.meta.breadcrumbs = getMessageByCode('dashboard') ?? 'Dashboard'
            if (isAuthenticated() && !isAdmin()) {
                next();
            } else if (isAuthenticated() && isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/clients",
        name: "clients",
        component: Clients,
        meta: {
            pageTitle: 'Client',
            breadcrumbs: ['Client'],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_CLIENT_VIEW")) {
                if (isAuthenticated() && isAdmin()) {
                    next();
                } else if (isAuthenticated() && !isAdmin()) {
                    ShowMessage("error", "Unauthorized");
                    router.push("/403")
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        }
    },
    {
        path: "/clientContacts",
        name: "clientContacts",
        component: clientContacts,
        meta: {
            pageTitle: 'Client Contacts',
            breadcrumbs: ['Client Contacts'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        }
    }, {
        path: "/clientcontact",
        name: "clientcontact",
        component: clientContact,
        meta: {
            pageTitle: 'Client Contact',
            breadcrumbs: ['Client Contact'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        }
    },
    {
        path: "/client",
        name: "client",
        component: AddClient,
        props: true,
        meta: {
            pageTitle: 'Client',
            breadcrumbs: ['Add'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/edit-client",
        name: "editClient",
        component: EditClient,
        props: true,
        meta: {
            pageTitle: "Edit Client",
            breadcrumbs: ["Client", "Edit"],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/providers",
        name: "providers",
        component: Providers,
        meta: {
            pageTitle: 'Providers',
            breadcrumbs: ['Providers'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (checkPermission("MD_PRO_VIEW")) {
                if (isAuthenticated()) {
                    // User is authenticated, allow access to the route
                    next();
                } else {
                    // User is not authenticated, redirect to the login page
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/provider",
        name: "add provider",
        component: AddProvider,
        meta: {
            pageTitle: 'Providers',
            breadcrumbs: ['Provider', 'Add'],
        },

        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/compliances",
        name: "Compliances",
        component: Compliance,
        meta: {
            pageTitle: "Compliances",
            breadcrumbs: ["Compliances"],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_COMP_VIEW")) {
                if (isAuthenticated() && isAdmin()) {
                    next();
                } else if (isAuthenticated() && !isAdmin()) {
                    ShowMessage("error", "Unauthorized");
                    router.push("/403")
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }

        },
    },
    //   {
    //     path: "/client_details",
    //     name: "add clients",
    //     component: AddClient,
    //     meta: {
    //       pageTitle: "Client",
    //       breadcrumbs: ["Client", "Add"],
    //     },
    //   },
    {
        path: "/properties",
        name: "Properties",
        component: Properties,
        meta: {
            pageTitle: 'Properties',
            breadcrumbs: ['Properties'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (checkPermission("MD_PROP_VIEW")) {
                if (isAuthenticated()) {
                    // User is authenticated, allow access to the route
                    next();
                } else {
                    // User is not authenticated, redirect to the login page
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/property",
        name: "add property",
        component: AddProperty,
        meta: {
            pageTitle: 'Property',
            breadcrumbs: ['Property', 'Add'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/pmsproperties",
        name: "PmsProperties",
        component: PmsProperties,
        meta: {
            pageTitle: 'Properties',
            breadcrumbs: ['Properties'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (checkPermission("MD_PMSPROP_VIEW")) {
                if (isAuthenticated()) {
                    // User is authenticated, allow access to the route
                    next();
                } else {
                    // User is not authenticated, redirect to the login page
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/rent",
        name: "PmsRent",
        component: PmsRent,
        meta: {
            pageTitle: 'Rent',
            breadcrumbs: ['Rent'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (checkPermission("PMS_RENT_VIEW")) {
                if (isAuthenticated()) {
                    // User is authenticated, allow access to the route
                    next();
                } else {
                    // User is not authenticated, redirect to the login page
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },

    {
        path: "/pms",
        name: "PmsDashboard",
        component: PmsDashboard,
        meta: {
            pageTitle: 'Dashboard',
            breadcrumbs: ['Dashboard'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }

        },
    },
    {
        path: "/addpmsproperty",
        name: "AddPmsProperties",
        component: AddPmsProperty,
        meta: {
            pageTitle: 'Property',
            breadcrumbs: ['Property', 'Add'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/pmsemails",
        name: "PmsEmails",
        component: PmsEmails,
        meta: {
            pageTitle: 'Emails',
            breadcrumbs: ['Emails'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/staticpages",
        name: "StaticPages",
        component: StaticPages,
        meta: {
            pageTitle: 'Static Pages',
            breadcrumbs: ['Static Pages'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check
            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    }, {
        path: "/faqs",
        name: "Faqs",
        component: Faqs,
        meta: {
            pageTitle: 'Faqs',
            breadcrumbs: ['Faqs'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    }, {
        path: "/staticpage",
        name: "StaticPage",
        component: StaticPage,
        meta: {
            pageTitle: 'Static Page',
            breadcrumbs: ['Add', 'Static Page'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },

    {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
        meta: {
            pageTitle: 'Contacts',
            breadcrumbs: ['Contacts'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/agreements",
        name: "TenancyAgreements",
        component: TenancyAgreements,
        meta: {
            pageTitle: 'Tenancy Agreements',
            breadcrumbs: ['TenancyAgreements'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
            pageTitle: 'Users',
            breadcrumbs: ['Users'],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_USER_VIEW")) {
                if (isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    }, {
        path: "/add-user",
        name: "Add User",
        component: AddUser,
        meta: {
            pageTitle: getMessageByCode('add_user') ?? 'Add User',
            breadcrumbs: [getMessageByCode('add_user') ?? 'Add User'],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_USER_CREATE")) {
                if (isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    //   {
    //     path: "/client_details",
    //     name: "add clients",
    //     component: AddClient,
    //     meta: {
    //       pageTitle: "Client",
    //       breadcrumbs: ["Client", "Add"],
    //     },
    //   },
    {
        path: "/tenants",
        name: "Tenants",
        component: Tenant,
        meta: {
            pageTitle: 'Tenants',
            breadcrumbs: ['Tenants'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (checkPermission("MD_TEN_VIEW")) {
                if (isAuthenticated()) {
                    // User is authenticated, allow access to the route
                    next();
                } else {
                    // User is not authenticated, redirect to the login page
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/tenant",
        name: "add tenant",
        component: AddTenant,
        meta: {
            pageTitle: 'Tenant',
            breadcrumbs: ['Tenant', 'Add'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    }, {
        path: "/pmstenants",
        name: "PmsTenants",
        component: PmsTenantList,
        meta: {
            pageTitle: 'Tenants',
            breadcrumbs: ['Tenants'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (checkPermission("PMS_TENANT_VIEW")) {
                if (isAuthenticated()) {
                    // User is authenticated, allow access to the route
                    next();
                } else {
                    // User is not authenticated, redirect to the login page
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/pmstenant",
        name: "add pms tenant",
        component: AddPmsTenant,
        meta: {
            pageTitle: 'Tenant',
            breadcrumbs: ['Tenant', 'Add'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated()) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    /*{
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            pageTitle: "Settings",
            breadcrumbs: ["Settings",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },*/
    {
        path: "/markets",
        name: "Markets",
        component: MarketList,
        meta: {
            pageTitle: "Markets",
            breadcrumbs: ["Markets",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/packages",
        name: "PackagesList",
        component: PackagesList,
        meta: {
            pageTitle: "Packages",
            breadcrumbs: ["Packages",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },

    {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            pageTitle: "Settings",
            breadcrumbs: ["Settings",],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_CONFIG_VIEW")) {
                if (isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    }, {
        path: "/templates",
        name: "templates",
        component: TemplatesList,
        meta: {
            pageTitle: "Templates",
            breadcrumbs: ["Templates",],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_TEMPLATE_VIEW")) {
                if (isAuthenticated()) {
                    next();

                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/roles",
        name: "roles",
        component: RolesList,
        meta: {
            pageTitle: "Roles",
            breadcrumbs: ["Roles",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && checkPermission("MD_ROLE_VIEW")) {
                next();
            } else {
                next('/');
            }
        },
    }, {
        path: "/roles-permissions",
        name: "roles-permissions",
        component: RolesPermissions,
        meta: {
            pageTitle: "Role Permissions",
            breadcrumbs: ["Role Permissions",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/options",
        name: "options",
        component: OptionsList,
        meta: {
            pageTitle: "Options",
            breadcrumbs: ["Options",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/documents", name: "documents", component: Documents, meta: {
            pageTitle: "Documents", breadcrumbs: ["Documents",],
        }, beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_DOCUMENT_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/profile", name: "profile", component: UserProfile, meta: {
            pageTitle: "Profile", breadcrumbs: ["Profile",],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_USER_PROFILE_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/empty_rooms", name: "emptyrooms", component: EmptyRooms, meta: {
            pageTitle: 'Empty Rooms', breadcrumbs: ['Empty Rooms',],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check
            if (isAuthenticated && checkPermission("MD_ROOM_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/document_expiry", name: "documentexpiry", component: DocumentsExpiry, meta: {
            pageTitle: "Documents Expiry", breadcrumbs: ["Documents Expiry",],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_DOC_EXP_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/missing_documents", name: "missingdocuments", component: MissingDocuments, meta: {
            pageTitle: "Missing Documents", breadcrumbs: ["Missing Documents"],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_DOC_MISS_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/tenants_report", name: "tenants_report", component: TenantsReport, meta: {
            pageTitle: "Tenants Report", breadcrumbs: ["Tenants Report",],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_TEN_REPORT_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/empty-rooms", name: "empty-rooms", component: RoomReport, meta: {
            pageTitle: "Empty Rooms", breadcrumbs: ["Empty Rooms",],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_ROOM_REP_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },

    {
        path: '/404',
        name: 'NotFound',
        component: PageNotFound
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    if (router.hasRoute(to.name)) {

        if (to.name === 'Login') {
            sessionStorage.clear();
        }

        next(); // Proceed with the navigation
    } else {
        next({name: 'NotFound'}); // Route not found
    }
});

//let propertyView = checkPermission("MD_PROP_VIEW");
//let clientView = checkPermission("MD_CLIENT_VIEW");
//let providerView = checkPermission("MD_PRO_VIEW");
//let tenantView = checkPermission("MD_TEN_VIEW");
//let complianceView = checkPermission("MD_COMP_VIEW");
//let userView = checkPermission("MD_USER_VIEW");
//let roleView = checkPermission("MD_ROLE_VIEW");
//let missDocView = checkPermission("MD_DOC_MISS_VIEW");
//let docExpView = checkPermission("MD_DOC_EXP_VIEW");
//let tenantReportView = checkPermission("MD_TEN_REPORT_VIEW");
//let roomReportView = checkPermission("MD_ROOM_REP_VIEW");
//let templateView = checkPermission("MD_TEMPLATE_VIEW");


export default router;
