<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" :options="SelectOptions" clear-id="providerId" @selection-changed="getProviders"
             :showSelect="true" :ShowAddBtn="providerCreate" routeName="provider"
             :btnText="getMessageByCode('add_pro') ?? 'Add Provider'">
      <template v-slot:select>
        <el-select v-if="clientId == null || clientId == 0" class="w-100" filterable @change="ChangeClient"
                   v-model="filter.clientId">
          <el-option
              v-for="item in Clients"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-end">
            <el-button v-if="selectedIds.length > 1"
                       class="bg-danger text-white fw-normal me-2 border-0"
                       @click="deleteProviderDialog = !deleteProviderDialog">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} {{ getMessageByCode('selected') ?? 'selected' }}
            </el-button>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-2 ms-auto d-flex justify-content-end">
          <el-input class="align-self-end" :placeholder="getMessageByCode('search') ?? 'Search here..'"
                    @clear="getProviders" v-model="filter.search"
                    @keyup="getProviders"
                    clearable>
            <template #append>
              <el-button @click="getProviders">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table :data="Providers" :default-sort="{ prop: 'name', order: 'ascending' }"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe @selection-change="handleSelectionChange">
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  small
              >
              </el-pagination>
            </template>
            <el-table-column :label="getMessageByCode('name') ?? 'Name'" prop="name" min-width="200" sortable>
              <template #default="scope">
            <span class="router-text" @click="editProvider(scope.row, 'edit')">{{
                scope.row.name
              }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('mobile') ?? 'Mobile'" prop="mobile" min-width="200"/>
            <el-table-column :label="getMessageByCode('address') ?? 'Address'" prop="address1" min-width="200"/>
            <el-table-column align="center" fixed="right"  :label="getMessageByCode('actions') ?? 'Actions'" width="100">
              <template #default="scope">
                <el-space alignment="center" spacer="|" v-if="providerDel">
                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editProvider(scope.row, 'edit')"
                  ></i>
                  <i  @click="editProvider(scope.row, 'delete')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
                <i v-else
                   class="mgc_edit_line text-primary action-btn"
                   @click="editProvider(scope.row, 'edit')"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading" class="spinner-border d-loader"></div>
  <DeleteProvider :selectedIds="selectedIds" :title="providerName" @deleteSuccess="getProviders"/>
  <el-dialog
      v-model="deleteProviderDialog"
      width="30%"
  >
    <template #header>
      <span>{{ getMessageByCode('delete') ?? 'Delete' }} {{ getMessageByCode('provider') ?? 'Provider' }}</span>
    </template>
    <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }} {{ provider.name }}?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteProviderDialog = false">{{
            getMessageByCode('cancel') ?? 'Cancel'
          }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteProviderDialog = false, deleteProvider()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import ProviderService from "@/Services/ComponentsServices/ProviderService";
import store from "@/store";
import DeleteProvider from "@/components/Providers/Delete/DeleteProvider.vue";
import clientService from "@/Services/ComponentsServices/clientService";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
import providerService from "@/Services/ComponentsServices/ProviderService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "ProvidersList",
  components: {DeleteProvider, ToolBar},
  data() {
    return {
      Providers: [],
      selectedIds: [],
      deleteProviderDialog: false,
      providerCreate: false,
      providerDel: false,
      providerName: "",
      provider: {},
      clientId: sessionStorage.getItem("clientId"),
      providerId: 0,
      percentage: 100,
      loading: false,
      SelectOptions: [{label: "All", value: 1}, {label: "Active", value: 2}, {label: "InActive", value: 3}],
      propertiesColumns: [
        {"label": "Name", "prop": "name", "width": "250"},
        {"label": "Email", "prop": "email", "width": "250"},
        {"label": "Mobile", "prop": "mobile", "width": "150"},
        {"label": "Address", "prop": "address1", "width": "400"},
      ],
      Clients: [],
      translations: [],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    editProvider(provider,type){
      this.provider = {...provider};
      switch (type){
        case 'edit':
          store.state.pageId = this.provider.id;
          this.$router.push('/provider');
          break;
        case 'delete':
          this.deleteProviderDialog = true;
          break;
      }
    },
    ChangeClient() {
      this.getProviders();
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },

    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getProviders();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getProviders();
    },
    async getProviders() {
      try {
        this.loading = true;
        let response = await ProviderService.All(this.filter);
        this.Providers = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;

        this.percentage = 100
        this.loading = false;

      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async deleteProvider() {
      try {
        let response = await providerService.deleteProvider(this.provider.id);
        if (response.status === 200) {
          ShowMessage("success", this.provider.name + " Deleted Successfully");
          await this.getProviders();
        }
      } catch (e) {
        console.log(e);
        ShowMessage("error", e?.response?.data?.message ?? "Something went wrong");
      }
    },
    async getClients() {
      try {

        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          this.$store.state.loading = true;
          let response = await clientService.getAll();
          this.Clients = response.data.items ?? [];
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
        this.getProviders();
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.providerDel = checkPermission("MD_PRO_DEL");
    this.providerCreate = checkPermission("MD_PRO_CREATE");
    this.getClients();
  }
}
</script>

<style scoped>

</style>