import config from "@/Configuration/Config"
import axios from 'axios';

export default {
    getReceiptsUrl: config.apiBaseUrl + "pmsemail/getemails?clientId=",
    getReceiptUrl: config.apiBaseUrl + "pmsemail/getemailbyid?emailId=",
    addReceiptUrl: config.apiBaseUrl + "pmsemail/add",
    updateReceiptUrl: config.apiBaseUrl + "pmsemail/update",
    deleteReceiptUrl: config.apiBaseUrl + "pmsemail/delete?emailId=",


    getEmails(filter) {
        return axios.get(this.getReceiptsUrl+filter.clientId+"&propertyId=" + filter.propertyId + "&tenantId=" + filter.tenantId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize);
    },
    getEmail(id) {
        return axios.get(this.getReceiptUrl + id);
    },
    addEmail(receipt) {
        return axios.post(this.addReceiptUrl, receipt);
    },
    updateEmail(receipt) {
        return axios.put(this.updateReceiptUrl, receipt);
    },
    deleteEmail(id) {
        return axios.delete(this.deleteReceiptUrl + id);
    }


}