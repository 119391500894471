import axios from 'axios';
import config from "@/Configuration/Config";

export default {
    getAgreementsUrl: config.apiBaseUrl + "tenancyagreement/getagreemnents?clientId=",
    getTenantAgreementsUrl: config.apiBaseUrl + "tenancyagreement/gettenantagreement?tenantId=",
    addUrl: config.apiBaseUrl+"tenancyagreement/add",
    updateUrl : config.apiBaseUrl+"tenancyagreement/update",
    deleteUrl : config.apiBaseUrl+"tenancyagreement/delete?agId=",

    getAgreements(filter) {
        return axios.get(this.getAgreementsUrl + filter.clientId + "&tenantId=" + filter.tenantId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize)
    },
    getTenantAgreements(filter) {
        return axios.get(this.getTenantAgreementsUrl + filter.clientId + "&tenantId=" + filter.tenantId + "&search=" + filter.search + "&page=" + filter.currentPage + "&limit=" + filter.pageSize)
    },
    addAgreement(agreement){
        return axios.post(this.addUrl,agreement);
    },
    updateAgreement(agreement) {
        return axios.put(this.updateUrl, agreement);
    },
    deleteAgreement(agreementId) {
        return axios.delete(this.deleteUrl+agreementId);
    },

}