<template>
  <div class="container-fluid">
    <!--
        <ToolBar :showCancel="true" back-route="providers" :show-select="false" :ShowAddBtn="true" :btn-text="mainBtnText"
                 @addbtn-clicked="AddOrUpdateProvider"/>
    -->

    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row align-items-center py-2">
        <div class="col-12 col-md-8">
          <BreadCrumb :view="edit" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit"  to="providers">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              {{getMessageByCode('cancel') ?? 'Cancel'}}
            </el-button>
          </router-link>
          <el-button v-else @click="checkProvider()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            {{getMessageByCode('cancel') ?? 'Cancel'}}
          </el-button>
          <el-button v-if="providerEdit && edit" class="border-0 mbtn-primary ms-2" @click="edit = !edit, activeTab = 0">
            {{getMessageByCode('edit') ?? 'Edit'}}
          </el-button>
          <el-button @click="AddProvider" v-if="Provider.id < 1 && providerCreate "
                     class="border-0 mbtn-primary ms-2 text-capitalize">{{getMessageByCode('add_pro') ?? 'Add Provider'}}
          </el-button>
          <el-button @click="UpdateProvider" v-if="Provider.id > 0 && providerEdit && !edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2 text-capitalize">{{getMessageByCode('upd_pro') ?? 'Update Provider'}}
          </el-button>
        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0">
      <el-tabs type="border-card" class="demo-tabs" v-model="activeTab">
        <el-tab-pane :name="0">
          <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-card-checklist me-1"></i>
          <span>{{getMessageByCode('details') ?? 'Details'}}</span>
        </span>
          </template>
          <el-form ref="providerForm" :model="Provider" label-position="top" require-asterisk-position="right">
            <div class="row my-row">
              <div class="col-12 col-md-6">
                <div class="card  border-0 shadow-sm ">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{getMessageByCode('provider') ?? 'Provider'}}</span>
                    <span class="bi bi-person"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item v-if="clientId === null || clientId === 0">
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('client') ?? 'Client'}}</span>
                      </template>
                      <el-select :disabled="edit" v-model="Provider.clientId" filterable placeholder="Select"
                                 class="w-100" @change="getCountries()">
                        <el-option
                            v-for="item in Clients"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            style="font-weight: normal; color: #0a0a0a"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="name" :rules="formRules.nameRule">
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('name') ?? 'Name'}}</span>
                      </template>
                      <el-input :disabled="edit" v-model="Provider.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="email" :rules="formRules.emailRule">
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('email') ?? 'Email'}}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="Provider.email"
                          type="text"

                      />
                    </el-form-item>
                    <el-form-item prop="mobile">
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('telephone') ?? 'Telephone'}}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="Provider.mobile"
                          type="text"

                      />
                    </el-form-item>

                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="card  border-0 shadow-sm ">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">{{getMessageByCode('address')??'Address'}}</span>
                    <span class="bi bi-sign-turn-right"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('address') ?? 'Address'}} 1</span>
                      </template>
                      <el-input :disabled="edit" v-model="Provider.address1"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('address') ?? 'Address'}} 2</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="Provider.address2"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('address') ?? 'Address'}} 3</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="Provider.address3"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('postcode') ?? 'Postcode'}}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="Provider.postCode"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('city') ?? 'City'}}</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="Provider.city"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span class="text-capitalize">{{getMessageByCode('country') ?? 'Country'}}</span>
                      </template>
                      <el-select :disabled="edit" v-model="Provider.county" filterable placeholder="Select"
                                 class="w-100">
                        <el-option
                            v-for="item in Countries"
                            :key="item.optTitle"
                            :label="item.optTitle"
                            :value="item.optTitle"
                            style="font-weight: normal; color: #0a0a0a"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
<!--              <div class="col-12 col-md-4">
                <div class="card  border-0 shadow-sm ">
                  <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                    <span class="card-title mb-0">Default Provider Login</span>
                    <span class="bi bi-key"></span>
                  </div>
                  <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
                  <div class="p-3">
                    <el-form-item>
                      <template #label>
                        <span>Display Name</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="Provider.displayName"
                          type="text"
                      />
                    </el-form-item>
                    <el-form-item>
                      <template #label>
                        <span>Username (email)</span>
                      </template>
                      <el-input
                          :disabled="edit"
                          v-model="Provider.userEmail"
                          type="text"
                      />
                    </el-form-item>
                  </div>
                </div>
              </div>-->
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="Provider.id>0 && edit" :name="1">
          <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-building-check me-1"></i>
         <span class="text-capitalize">{{getMessageByCode('properties') ?? 'Properties'}}</span>
        </span>
          </template>
          <el-table :data="providerProperties"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  small
              >
              </el-pagination>
            </template>
            <el-table-column min-width="150" :label="getMessageByCode('title') ?? 'Title'" prop="titleNo"/>
            <el-table-column min-width="250" :label="getMessageByCode('address') ?? 'Address'"  prop="address1">
              <template #default="scope">
            <span class="router-text" @click="goToProperty(scope.row.id)">{{
                scope.row.address1
              }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="filter.clientId === 0" min-width="150" :label="getMessageByCode('client') ?? 'Client'"  prop="clientName"/>
            <el-table-column min-width="150" :label="getMessageByCode('city') ?? 'City'"  prop="city"/>
            <el-table-column min-width="80" :label="getMessageByCode('postcode') ?? 'Postcode'"  prop="postCode"/>
            <el-table-column :label="getMessageByCode('rooms') ?? 'Rooms'"  width="100" prop="numberOfRooms">
              <template #default="scope">
                <div class="text-end">
                  <span>{{ scope.row.numberOfRooms }}</span>
                </div>
              </template>
            </el-table-column>
<!--            <el-table-column align="center" label="Actions" width="120">-->
<!--              <template>-->
<!--                <el-space alignment="center" spacer="|" v-if="propertyDel">-->
<!--                  <i-->
<!--                      class="mgc_edit_line text-primary action-btn">-->
<!--                    &lt;!&ndash;                      @click="editProperty(scope.row.id)"&ndash;&gt;-->
<!--                  </i>-->
<!--                  <i-->
<!--                      class="mgc_delete_2_line text-danger action-btn"-->
<!--                  ></i>-->
<!--                </el-space>-->
<!--                <i v-else-->
<!--                   class="mgc_edit_line text-primary action-btn"-->

<!--                ></i>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="Provider.id>0 && edit" :name="2">
          <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-people me-1"></i>
          <span class="text-capitalize">{{getMessageByCode('tenants') ?? 'Tenants'}} </span>
        </span>
          </template>
          <el-table :data="providerTenants"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe>
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  small
              >
              </el-pagination>
            </template>
            <el-table-column :label="getMessageByCode('first_name') ?? 'First Name'" prop="address1">
              <template #default="scope">
            <span class="router-text" @click="goToTenant(scope.row.id)">{{
                scope.row.firstName
              }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('last_name') ?? 'Last Name'" width="auto" prop="lastName"/>
            <el-table-column :label="getMessageByCode('mobile') ?? 'Mobile'" width="auto" prop="mobile"/>
            <el-table-column :label="getMessageByCode('email') ?? 'Email'" width="auto" prop="email"/>
            <el-table-column :label="getMessageByCode('address') ?? 'Address'" width="auto" prop="address1"/>
            <el-table-column :label="getMessageByCode('city') ?? 'City'" resizable width="auto" prop="city"/>
<!--            <el-table-column align="center" label="Actions" width="120">-->
<!--              <template #default="scope">-->
<!--                <el-space alignment="center" spacer="|">-->
<!--                  <i-->
<!--                      class="mgc_edit_line text-primary action-btn"-->
<!--                      @click="editTenant(scope.row.id)"-->
<!--                  ></i>-->
<!--                  <i v-show="tenantDel" @click="deleteTenantId(scope.row)"-->
<!--                     class="mgc_delete_2_line text-danger action-btn"-->
<!--                  ></i>-->
<!--                </el-space>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import {ShowMessage} from "@/Utility/Utility";
import store from "@/store";
import ProviderService from "@/Services/ComponentsServices/ProviderService";
import ClientService from "@/Services/ComponentsServices/clientService";
import optionService from "@/Services/ComponentsServices/optionService";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {checkPermission} from "@/Utility/CheckPermission";
import propertyService from "@/Services/ComponentsServices/PropertyService";
import tenantService from "@/Services/ComponentsServices/tenantService";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "AddProvider",
  components: {BreadCrumb},
  data() {
    return {
      activeTab: 0,
      providerEdit: false,
      providerCreate: false,
      propertyDel: false,
      tenantCreate: false,
      tenantDel: false,
      edit: true,
      clientId: sessionStorage.getItem("clientId"),
      providerProperties: [],
      providerTenants: [],
      mainBtnText: "Add Client",
      addDisabled: false,
      loading: false,
      Clients: [],
      Countries: [],
      client: {},
      formRules: {
        nameRule: [
          {required: true, message: 'Please enter your name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        emailRule: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
        userEmail: [
          {required: true, message: 'Please enter the user email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid user email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
      },
      Provider: {
        id: store.state.pageId,
        "clientId": sessionStorage.getItem("clientId"),
        "name": "",
        "email": "",
        "mobile": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "postCode": "",
        "city": "",
        "county": "",
        "clientName": "",
        "userEmail": "",
        "isActive": true
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
      translations:[],
    }
  },
  methods: {
    checkProvider(){
      if(this.Provider.id < 1){
        this.$router.push('providers');
        return;
      }
      this.edit = !this.edit
    },
    goToProperty(id){
      store.state.pageId = id;
      this.$router.push('/property')
    },goToTenant(id){
      store.state.pageId = id;
      this.$router.push('/tenant')
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      if (this.activeTab === 1) {
        this.getProviderProperties();
      }
      if (this.activeTab === 2) {
        this.getProviderTenants();
      }

    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      if (this.activeTab === 1) {
        this.getProviderProperties();
      }
      if (this.activeTab === 2) {
        this.getProviderTenants();
      }
    },
    validateEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback(new Error('Please enter a valid email address'));
      }
    },
    SetClient(item) {
      this.Provider.clientName = item.name;
      this.Provider.clientId = item.id;
    },
    async getClients() {
      try {
        this.loading = true;
        let response = await ClientService.getAll();
        this.Clients = response.data.items ?? [];
        if (this.Clients.length > 0) {
          if (this.Provider.clientId < 1) {
            this.Provider.clientId = this.Clients[0].id;
          }
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },

    async AddProvider() {
      this.loading = true;
      await this.$refs.providerForm.validate(async (valid) => {
        if (valid) {
          try {

            // eslint-disable-next-line no-unused-vars
            let response = await ProviderService.add(this.Provider);

            ShowMessage("success", "Provider added successful");
            this.loading = false;
          } catch (e) {
            console.log(e)
            ShowMessage("error", e.response?.data?.message ?? "Provider adding failed");
            this.loading = false;
          }
        } else {
          ShowMessage("error", "Please enter the required fields!")
        }
      })
    },
    async getProviderProperties() {
      this.loading = true;
      try {
        let response = await propertyService.getPropertyByProvider(this.filter);
        this.providerProperties = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ??  1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getProviderTenants() {
      this.loading = true;
      try {
        let response = await tenantService.getAllProviderTenants(this.filter);
        this.providerTenants = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ??  1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async UpdateProvider() {
      this.loading = true;
      await this.$refs.providerForm.validate(async (valid) => {
        if (valid) {
          try {
            // eslint-disable-next-line no-unused-vars
            let response = await ProviderService.update(this.Provider);

            ShowMessage("success", "Provider updated successfully");
            this.loading = false;
          } catch (e) {
            console.log(e)
            ShowMessage("error", "Provider updating failed");
            this.loading = false;
          }
        } else {
          ShowMessage("error", "Please enter the required fields!")
        }
      });
    },
    async getProvider() {
      try {
        this.loading = true;
        let response = await ProviderService.get(this.Provider.id);
        this.Provider = response.data ?? {};
        await this.getClients();
        this.filter.providerId = this.Provider.id;
        this.loading = false;
        this.getProviderProperties();
        this.getProviderTenants();
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    }
    ,
    async getCountries() {
      try {
        this.loading = true;
        if (this.Provider.clientId === null) {
          this.Provider.clientId = 1;
        }
        let response = await optionService.getOptionsByHeaderId(this.Provider.clientId, 7)
        this.Countries = response.data ?? {};
        if (this.Countries.length > 0) {
          this.Provider.county = this.Countries[0].optTitle;
        } else {
          this.Provider.county = "";
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.providerEdit = checkPermission("MD_PRO_EDIT");
    this.providerCreate = checkPermission("MD_PRO_CREATE");
    this.propertyDel = checkPermission("MD_PROP_DEL");
    this.tenantCreate = checkPermission("MD_TEN_CREATE");
    this.tenantDel = checkPermission("MD_TEN_DEL");
    if (store.state.pageId > 0) {
      this.mainBtnText = "Update Provider";
      this.getProvider();
    } else {
      this.mainBtnText = "Add Provider";
      this.edit = false;
      this.getClients();
    }
    this.getCountries();
  }
}
</script>

<style scoped>

</style>